import React, { useState } from 'react';

import {
  HomeAgeCategory,
  MobileHomeType,
  SurveyFlowType,
  SurveyResult,
} from './survey.types';

interface SurveyProps {
  setSurveyVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onSurveyComplete: (surveyResult: SurveyResult) => any;
}

export const Survey = ({
  setSurveyVisible,
  onSurveyComplete,
}: SurveyProps): JSX.Element => {
  const [surveyResult, setSurveyResult] = useState<SurveyResult | null>(null);

  const handleSurveySubmit = (): void => {
    setSurveyVisible(false);
    onSurveyComplete(surveyResult!);
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div className="mt-3 text-center sm:mt-5">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                Before you go, would you be willing to tell us a little more
                about what you're looking for?
              </h3>
              <div className="mt-2">
                <div className="my-8">
                  <p className="mb-2 font-semibold">What do you want to do?</p>
                  <div className="flex space-x-4">
                    <button
                      type="button"
                      onClick={() => {
                        setSurveyResult({
                          ...surveyResult,
                          surveyFlowType: SurveyFlowType.BuyAHome,
                        });
                      }}
                      className={`${
                        surveyResult?.surveyFlowType === SurveyFlowType.BuyAHome
                          ? 'bg-blue-600'
                          : 'bg-gray-300'
                      } w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm`}
                    >
                      Buy a Home
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setSurveyResult({
                          ...surveyResult,
                          surveyFlowType: SurveyFlowType.Refinance,
                        });
                      }}
                      className={`${
                        surveyResult?.surveyFlowType ===
                        SurveyFlowType.Refinance
                          ? 'bg-blue-600'
                          : 'bg-gray-300'
                      } w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm`}
                    >
                      Refinance
                    </button>
                  </div>
                </div>

                <div className="my-8">
                  <p className="mb-2 font-semibold">
                    What kind of mobile home is it?
                  </p>
                  <div className="flex space-x-4">
                    <button
                      type="button"
                      onClick={() => {
                        setSurveyResult({
                          ...surveyResult,
                          mobileHomeType: MobileHomeType.SingleWide,
                        });
                      }}
                      className={`${
                        surveyResult?.mobileHomeType ===
                        MobileHomeType.SingleWide
                          ? 'bg-blue-600'
                          : 'bg-gray-300'
                      } w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm`}
                    >
                      Single Wide
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setSurveyResult({
                          ...surveyResult,
                          mobileHomeType: MobileHomeType.DoubleWide,
                        });
                      }}
                      className={`${
                        surveyResult?.mobileHomeType ===
                        MobileHomeType.DoubleWide
                          ? 'bg-blue-600'
                          : 'bg-gray-300'
                      } w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm`}
                    >
                      Double Wide
                    </button>
                  </div>
                </div>

                <div className="my-8">
                  <p className="mb-2 font-semibold">How old is the home?</p>
                  <div className="flex space-x-2">
                    <button
                      type="button"
                      onClick={() => {
                        setSurveyResult({
                          ...surveyResult,
                          homeAge: HomeAgeCategory.LessThan3Years,
                        });
                      }}
                      className={`${
                        surveyResult?.homeAge === HomeAgeCategory.LessThan3Years
                          ? 'bg-blue-600'
                          : 'bg-gray-300'
                      } w-3/8 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm`}
                    >
                      Less than 3 Years
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setSurveyResult({
                          ...surveyResult,
                          homeAge: HomeAgeCategory.Between3And9Years,
                        });
                      }}
                      className={`${
                        surveyResult?.homeAge ===
                        HomeAgeCategory.Between3And9Years
                          ? 'bg-blue-600'
                          : 'bg-gray-300'
                      } w-1/8 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm`}
                    >
                      3-9 Years
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setSurveyResult({
                          ...surveyResult,
                          homeAge: HomeAgeCategory.Between10And19Years,
                        });
                      }}
                      className={`${
                        surveyResult?.homeAge ===
                        HomeAgeCategory.Between10And19Years
                          ? 'bg-blue-600'
                          : 'bg-gray-300'
                      } w-2/8 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm`}
                    >
                      10-19 Years
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setSurveyResult({
                          ...surveyResult,
                          homeAge: HomeAgeCategory.TwentyOrMoreYears,
                        });
                      }}
                      className={`${
                        surveyResult?.homeAge ===
                        HomeAgeCategory.TwentyOrMoreYears
                          ? 'bg-blue-600'
                          : 'bg-gray-300'
                      } w-2/8 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm`}
                    >
                      20+ Years
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <button
              type="button"
              onClick={handleSurveySubmit}
              disabled={false}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={() => {
                setSurveyVisible(false);
              }}
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:col-start-1 sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
