import { useState } from 'react';

import { Transition } from '@headlessui/react';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

import { CreateProspectDto, ProspectDto } from 'api/prospect';
import { API_BASE_URL_BACKEND } from 'common/constants';

import { Survey } from './survey/survey-input';
import { SurveyResult } from './survey/survey.types';

interface EmailInputSurveyTriggerProps {
  onEmailSubmit?: (emailProspect: ProspectDto) => any;
  onSurveySubmit?: (fullProspect: ProspectDto) => any;
}

export const EmailInputSurveyTrigger = ({
  onEmailSubmit,
  onSurveySubmit,
}: EmailInputSurveyTriggerProps): JSX.Element => {
  const [prospect, setProspect] = useState<ProspectDto | null>(null);
  const [surveyVisible, setSurveyVisible] = useState(false);
  const { register, reset, handleSubmit } = useForm();

  const handleEmailSubmit = async (formData: ProspectDto): Promise<void> => {
    const currProspect: CreateProspectDto = {
      emailAddress: formData.emailAddress!,
    };

    try {
      const response = await axios.post(
        `${API_BASE_URL_BACKEND}/prospect`,
        currProspect,
      );

      setProspect(response.data);

      if (onEmailSubmit) {
        onEmailSubmit(response.data);
      }
    } catch (error) {
      Sentry.captureException(error);
    }

    reset();
    setSurveyVisible(true);
  };

  const handleSurveySubmit = async (
    surveyResults: SurveyResult,
  ): Promise<void> => {
    const updatedProspect: ProspectDto = {
      emailAddress: prospect?.emailAddress,
      id: prospect?.id!,
      homeAgeCategory: surveyResults.homeAge,
      interestedAction: surveyResults.surveyFlowType,
      mobileHomeType: surveyResults.mobileHomeType,
    };

    try {
      await axios.put(
        `${API_BASE_URL_BACKEND}/prospect/${prospect!.id}`,
        updatedProspect,
      );
    } catch (error) {
      Sentry.captureException(error);
    }

    if (onSurveySubmit) {
      onSurveySubmit(updatedProspect);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleEmailSubmit)}>
        <div className="mt-4 sm:flex sm:max-w-md">
          <label htmlFor="emailAddress" className="sr-only">
            Email address
          </label>
          <input
            type="email"
            name="emailAddress"
            ref={register}
            id="emailAddress"
            autoComplete="email"
            required
            className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:placeholder-gray-400"
            placeholder="Enter your email"
          />
          <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
            <button className="w-full bg-blue-600 flex items-center justify-center border border-transparent rounded-md py-2 px-4 text-base font-medium text-white hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              Submit
            </button>
          </div>
        </div>
      </form>
      <Transition
        show={surveyVisible}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Survey
          onSurveyComplete={handleSurveySubmit}
          setSurveyVisible={setSurveyVisible}
        />
      </Transition>
    </>
  );
};
