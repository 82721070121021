export const PercentGuy = (): JSX.Element => {
  return (
    <svg
      width="185"
      height="110"
      viewBox="0 0 185 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.1">
        <path
          opacity="0.1"
          d="M119.54 103.974C121.276 103.974 122.683 102.133 122.683 99.8621C122.683 97.5909 121.276 95.7498 119.54 95.7498C117.805 95.7498 116.398 97.5909 116.398 99.8621C116.398 102.133 117.805 103.974 119.54 103.974Z"
          fill="#3F3D56"
        />
        <path
          opacity="0.1"
          d="M122.027 82.2692C122.467 81.5051 122.695 80.6374 122.688 79.7557C122.688 77.4797 121.282 75.6433 119.546 75.6433C117.811 75.6433 116.405 77.484 116.405 79.7557C116.396 80.6369 116.623 81.5046 117.061 82.2692C116.631 83.0369 116.405 83.9021 116.405 84.782C116.405 85.6619 116.631 86.5271 117.061 87.2948C116.631 88.0627 116.405 88.9281 116.405 89.8083C116.405 90.6884 116.631 91.5538 117.061 92.3218C116.623 93.0864 116.396 93.954 116.405 94.8353C116.405 97.1112 117.812 98.9477 119.546 98.9477C121.28 98.9477 122.688 97.107 122.688 94.8353C122.697 93.954 122.471 93.0864 122.033 92.3218C122.463 91.5538 122.688 90.6884 122.688 89.8083C122.688 88.9281 122.463 88.0627 122.033 87.2948C122.463 86.5271 122.688 85.6619 122.688 84.782C122.688 83.9021 122.463 83.0369 122.033 82.2692H122.027Z"
          fill="#3F3D56"
        />
        <path
          opacity="0.1"
          d="M119.54 78.8424C121.276 78.8424 122.683 77.0012 122.683 74.73C122.683 72.4588 121.276 70.6177 119.54 70.6177C117.805 70.6177 116.398 72.4588 116.398 74.73C116.398 77.0012 117.805 78.8424 119.54 78.8424Z"
          fill="#3F3D56"
        />
        <path
          opacity="0.1"
          d="M119.54 73.8155C121.276 73.8155 122.683 71.9744 122.683 69.7032C122.683 67.432 121.276 65.5908 119.54 65.5908C117.805 65.5908 116.398 67.432 116.398 69.7032C116.398 71.9744 117.805 73.8155 119.54 73.8155Z"
          fill="#3F3D56"
        />
        <path
          opacity="0.1"
          d="M131.301 35.3045C131.73 34.7578 132.121 34.1822 132.47 33.5819L124.22 32.2263L133.149 32.2931C134.005 30.4295 134.471 28.411 134.52 26.3609C134.569 24.3108 134.199 22.2723 133.433 20.37L121.453 26.5805L132.503 18.4639C131.468 16.6768 130.075 15.122 128.413 13.8963C126.751 12.6705 124.854 11.8001 122.841 11.3391C120.827 10.8781 118.741 10.8365 116.711 11.2168C114.68 11.5971 112.75 12.3912 111.04 13.5496C109.33 14.7081 107.877 16.2061 106.771 17.9504C105.665 19.6947 104.93 21.648 104.612 23.6887C104.293 25.7294 104.398 27.8139 104.92 29.8122C105.442 31.8106 106.37 33.6801 107.646 35.3045C106.977 36.155 106.402 37.0749 105.93 38.0484L116.645 43.616L105.22 39.7753C104.416 42.146 104.216 44.6798 104.639 47.1472C105.061 49.6146 106.092 51.9377 107.638 53.9062C105.892 56.1258 104.806 58.792 104.504 61.5999C104.202 64.4077 104.696 67.2439 105.93 69.7842C107.164 72.3245 109.088 74.4663 111.481 75.9648C113.875 77.4632 116.642 78.2579 119.466 78.2579C122.29 78.2579 125.057 77.4632 127.451 75.9648C129.845 74.4663 131.769 72.3245 133.002 69.7842C134.236 67.2439 134.73 64.4077 134.428 61.5999C134.126 58.792 133.04 56.1258 131.294 53.9062C133.38 51.2538 134.515 47.9771 134.515 44.6025C134.515 41.2279 133.38 37.9512 131.294 35.2988L131.301 35.3045Z"
          fill="#005CFF"
        />
        <path
          opacity="0.1"
          d="M104.425 44.6094C104.42 47.9847 105.555 51.2628 107.646 53.9124C105.9 56.1321 104.814 58.7982 104.511 61.6061C104.209 64.4139 104.703 67.2501 105.937 69.7904C107.171 72.3307 109.095 74.4725 111.489 75.971C113.882 77.4695 116.649 78.2641 119.473 78.2641C122.298 78.2641 125.065 77.4695 127.458 75.971C129.852 74.4725 131.776 72.3307 133.01 69.7904C134.243 67.2501 134.738 64.4139 134.435 61.6061C134.133 58.7982 133.047 56.1321 131.301 53.9124C133.318 51.3519 104.425 42.9195 104.425 44.6094Z"
          fill="black"
        />
      </g>
      <path
        d="M162.589 101.454C162.589 102.431 155.445 103.324 143.634 104.014C141.486 104.138 139.185 104.256 136.744 104.367C134.635 104.462 132.424 104.551 130.112 104.636C127.872 104.715 125.547 104.789 123.135 104.858C115.519 105.075 107.099 105.232 98.155 105.31C95.9843 105.33 93.7847 105.345 91.5561 105.356C89.2346 105.366 86.8866 105.371 84.512 105.37C41.3913 105.37 6.43689 103.619 6.43689 101.458C6.43689 99.2973 41.3913 97.5462 84.512 97.5462C103.077 97.5462 120.125 97.8719 133.525 98.4125L134.99 98.4722H134.998C151.877 99.1877 162.589 100.265 162.589 101.454Z"
        fill="#3F3D56"
      />
      <path
        opacity="0.15"
        d="M162.589 101.454C162.589 102.431 155.445 103.324 143.634 104.014C141.486 104.138 139.185 104.256 136.744 104.367C134.635 104.462 132.424 104.551 130.112 104.636C127.872 104.715 125.547 104.789 123.135 104.858C115.519 105.075 107.099 105.232 98.155 105.31C95.9843 105.33 93.7847 105.345 91.5561 105.356C89.2346 105.366 86.8866 105.371 84.512 105.37C41.3913 105.37 6.43689 103.619 6.43689 101.458C6.43689 99.2973 41.3913 97.5462 84.512 97.5462C103.077 97.5462 120.125 97.8719 133.525 98.4125L134.99 98.4722H134.998C151.877 99.1877 162.589 100.265 162.589 101.454Z"
        fill="black"
      />
      <path
        d="M156.152 100.765C156.152 101.582 149.008 102.33 137.197 102.899C135.051 103.003 132.748 103.101 130.308 103.193C128.198 103.273 125.988 103.348 123.677 103.418C121.437 103.484 119.111 103.547 116.7 103.604C109.082 103.787 100.663 103.917 91.7181 103.983C89.5493 103.999 87.3497 104.011 85.1193 104.02C82.7978 104.028 80.4503 104.033 78.0766 104.033C34.9559 104.033 6.10352e-05 102.569 6.10352e-05 100.761C6.10352e-05 98.953 34.9559 97.4893 78.0766 97.4893C96.6399 97.4893 113.688 97.761 127.088 98.2133L128.553 98.2631H128.563C145.448 98.8705 156.152 99.7652 156.152 100.765Z"
        fill="#8C8B95"
      />
      <path
        d="M20.1649 71.0451L18.2047 65.0437L65.1462 64.5302L67.0694 70.4718L20.1649 71.0451Z"
        fill="#3F3D56"
      />
      <path
        opacity="0.15"
        d="M20.1649 71.0451L18.2047 65.0437L65.1462 64.5302L67.0694 70.4718L20.1649 71.0451Z"
        fill="black"
      />
      <path
        d="M17.0355 71.0451L15.0754 65.0437L62.0169 64.5302L63.9401 70.4718L17.0355 71.0451Z"
        fill="#8C8B95"
      />
      <path
        d="M97.1706 61.8234C92.3171 61.8234 88.4921 59.8969 85.6955 56.0439C82.8989 52.1909 81.5006 47.5233 81.5006 42.0411C81.5006 36.5219 82.8529 31.8638 85.5575 28.0667C88.2621 24.2697 92.0772 22.373 97.0027 22.3768C102.226 22.3768 106.162 24.3773 108.809 28.3782C111.457 32.3792 112.781 37.0098 112.782 42.2701C112.782 47.4925 111.412 52.0576 108.671 55.9656C105.931 59.8736 102.097 61.8262 97.1706 61.8234ZM97.3128 57.4891C100.506 57.4891 102.892 56.0975 104.472 53.3142C106.052 50.5309 106.842 47.0221 106.842 42.7879C106.842 38.0748 106.034 34.2156 104.417 31.2104C102.8 28.2052 100.32 26.7021 96.9771 26.7011C90.6234 26.7011 87.4466 31.7481 87.4466 41.8419C87.4466 52.2743 90.7344 57.4901 97.31 57.4891H97.3128ZM98.9117 98.9954H91.8918L137.849 23.1492H144.737L98.9117 98.9954ZM139.569 99.7735C134.642 99.7735 130.802 97.8294 128.047 93.9413C125.292 90.0533 123.909 85.3852 123.897 79.9371C123.897 74.4549 125.24 69.8153 127.926 66.0182C130.611 62.2212 134.436 60.3246 139.399 60.3284C144.586 60.3284 148.494 62.3288 151.123 66.3298C153.753 70.3307 155.068 74.9608 155.069 80.2202C155.069 85.4084 153.708 89.9651 150.985 93.8901C148.263 97.8152 144.456 99.7763 139.566 99.7735H139.569ZM139.651 95.3283C142.829 95.3283 145.203 93.9603 146.772 91.2244C148.342 88.4885 149.126 84.994 149.126 80.7408C149.126 76.1889 148.332 72.3715 146.744 69.2885C145.155 66.2055 142.681 64.6617 139.32 64.6569C132.929 64.6569 129.734 69.7039 129.734 79.7977C129.732 90.1524 133.037 95.3292 139.648 95.3283H139.651Z"
        fill="#3F3D56"
      />
      <path
        d="M97.1706 61.8234C92.3171 61.8234 88.4921 59.8969 85.6955 56.0439C82.8989 52.1909 81.5006 47.5233 81.5006 42.0411C81.5006 36.5219 82.8529 31.8638 85.5575 28.0667C88.2621 24.2697 92.0772 22.373 97.0027 22.3768C102.226 22.3768 106.162 24.3773 108.809 28.3782C111.457 32.3792 112.781 37.0098 112.782 42.2701C112.782 47.4925 111.412 52.0576 108.671 55.9656C105.931 59.8736 102.097 61.8262 97.1706 61.8234ZM97.3128 57.4891C100.506 57.4891 102.892 56.0975 104.472 53.3142C106.052 50.5309 106.842 47.0221 106.842 42.7879C106.842 38.0748 106.034 34.2156 104.417 31.2104C102.8 28.2052 100.32 26.7021 96.9771 26.7011C90.6234 26.7011 87.4466 31.7481 87.4466 41.8419C87.4466 52.2743 90.7344 57.4901 97.31 57.4891H97.3128ZM98.9117 98.9954H91.8918L137.849 23.1492H144.737L98.9117 98.9954ZM139.569 99.7735C134.642 99.7735 130.802 97.8294 128.047 93.9413C125.292 90.0533 123.909 85.3852 123.897 79.9371C123.897 74.4549 125.24 69.8153 127.926 66.0182C130.611 62.2212 134.436 60.3246 139.399 60.3284C144.586 60.3284 148.494 62.3288 151.123 66.3298C153.753 70.3307 155.068 74.9608 155.069 80.2202C155.069 85.4084 153.708 89.9651 150.985 93.8901C148.263 97.8152 144.456 99.7763 139.566 99.7735H139.569ZM139.651 95.3283C142.829 95.3283 145.203 93.9603 146.772 91.2244C148.342 88.4885 149.126 84.994 149.126 80.7408C149.126 76.1889 148.332 72.3715 146.744 69.2885C145.155 66.2055 142.681 64.6617 139.32 64.6569C132.929 64.6569 129.734 69.7039 129.734 79.7977C129.732 90.1524 133.037 95.3292 139.648 95.3283H139.651Z"
        fill="#3F3D56"
      />
      <path
        opacity="0.15"
        d="M97.1706 61.8234C92.3171 61.8234 88.4921 59.8969 85.6955 56.0439C82.8989 52.1909 81.5006 47.5233 81.5006 42.0411C81.5006 36.5219 82.8529 31.8638 85.5575 28.0667C88.2621 24.2697 92.0772 22.373 97.0027 22.3768C102.226 22.3768 106.162 24.3773 108.809 28.3782C111.457 32.3792 112.781 37.0098 112.782 42.2701C112.782 47.4925 111.412 52.0576 108.671 55.9656C105.931 59.8736 102.097 61.8262 97.1706 61.8234ZM97.3128 57.4891C100.506 57.4891 102.892 56.0975 104.472 53.3142C106.052 50.5309 106.842 47.0221 106.842 42.7879C106.842 38.0748 106.034 34.2156 104.417 31.2104C102.8 28.2052 100.32 26.7021 96.9771 26.7011C90.6234 26.7011 87.4466 31.7481 87.4466 41.8419C87.4466 52.2743 90.7344 57.4901 97.31 57.4891H97.3128ZM98.9117 98.9954H91.8918L137.849 23.1492H144.737L98.9117 98.9954ZM139.569 99.7735C134.642 99.7735 130.802 97.8294 128.047 93.9413C125.292 90.0533 123.909 85.3852 123.897 79.9371C123.897 74.4549 125.24 69.8153 127.926 66.0182C130.611 62.2212 134.436 60.3246 139.399 60.3284C144.586 60.3284 148.494 62.3288 151.123 66.3298C153.753 70.3307 155.068 74.9608 155.069 80.2202C155.069 85.4084 153.708 89.9651 150.985 93.8901C148.263 97.8152 144.456 99.7763 139.566 99.7735H139.569ZM139.651 95.3283C142.829 95.3283 145.203 93.9603 146.772 91.2244C148.342 88.4885 149.126 84.994 149.126 80.7408C149.126 76.1889 148.332 72.3715 146.744 69.2885C145.155 66.2055 142.681 64.6617 139.32 64.6569C132.929 64.6569 129.734 69.7039 129.734 79.7977C129.732 90.1524 133.037 95.3292 139.648 95.3283H139.651Z"
        fill="black"
      />
      <path
        d="M92.761 61.8234C87.9075 61.8234 84.0825 59.8969 81.2859 56.0439C78.4894 52.1909 77.0911 47.5233 77.0911 42.0411C77.0911 36.5219 78.4434 31.8638 81.1479 28.0667C83.8525 24.2697 87.6676 22.373 92.5931 22.3768C97.8165 22.3768 101.752 24.3773 104.4 28.3782C107.047 32.3792 108.372 37.0098 108.373 42.2701C108.373 47.4925 107.002 52.0576 104.262 55.9656C101.521 59.8736 97.6875 61.8262 92.761 61.8234ZM92.9032 57.4891C96.0962 57.4891 98.4827 56.0975 100.063 53.3142C101.642 50.5309 102.432 47.0221 102.432 42.7879C102.432 38.0748 101.624 34.2156 100.007 31.2104C98.3902 28.2052 95.9103 26.7021 92.5675 26.7011C86.2138 26.7011 83.037 31.7481 83.037 41.8419C83.037 52.2743 86.3248 57.4901 92.9004 57.4891H92.9032ZM94.5021 98.9954H87.4822L133.439 23.1492H140.327L94.5021 98.9954ZM135.159 99.7735C130.233 99.7735 126.392 97.8294 123.637 93.9413C120.882 90.0533 119.499 85.3852 119.488 79.9371C119.488 74.4549 120.831 69.8153 123.516 66.0182C126.202 62.2212 130.026 60.3246 134.99 60.3284C140.176 60.3284 144.084 62.3288 146.714 66.3298C149.344 70.3307 150.659 74.9608 150.66 80.2202C150.66 85.4084 149.299 89.9651 146.576 93.8901C143.853 97.8152 140.047 99.7763 135.156 99.7735H135.159ZM135.242 95.3283C138.419 95.3283 140.793 93.9603 142.363 91.2244C143.932 88.4885 144.717 84.994 144.717 80.7408C144.717 76.1889 143.923 72.3715 142.334 69.2885C140.746 66.2055 138.27 64.6617 134.907 64.6569C128.517 64.6569 125.321 69.7039 125.321 79.7977C125.321 90.1524 128.627 95.3292 135.239 95.3283H135.242Z"
        fill="#8C8B95"
      />
      <path
        d="M108.118 100.899C108.704 99.8123 108.038 98.4808 107.273 97.5107C106.507 96.5406 105.574 95.5335 105.594 94.2973C105.623 92.5235 107.506 91.4752 109.008 90.5335C110.125 89.8356 111.156 89.0076 112.078 88.0669C112.464 87.7045 112.783 87.2769 113.021 86.8038C113.332 86.1096 113.322 85.3201 113.305 84.5605C113.24 82.0266 113.115 79.4965 112.93 76.9702"
        stroke="#3F3D56"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M115.792 76.8305C115.771 76.367 115.633 75.9164 115.392 75.5201C115.15 75.1238 114.813 74.7947 114.411 74.5631L113.791 75.7893L113.809 74.3042C113.513 74.2178 113.205 74.1808 112.897 74.1947C112.35 74.2203 111.823 74.4075 111.383 74.7327C110.943 75.0578 110.608 75.5062 110.423 76.0213C110.237 76.5363 110.208 77.0948 110.34 77.6262C110.472 78.1576 110.758 78.6379 111.163 79.0065C111.568 79.3751 112.073 79.6154 112.614 79.697C113.156 79.7786 113.709 79.6978 114.205 79.4649C114.7 79.232 115.115 78.8574 115.398 78.3885C115.68 77.9196 115.818 77.3774 115.792 76.8305Z"
        fill="#005CFF"
      />
      <path
        d="M110.856 95.57C110.566 96.1344 110.09 96.5817 109.509 96.837C108.928 97.0923 108.277 97.14 107.665 96.9722C107.052 96.8043 106.517 96.4311 106.147 95.9152C105.777 95.3992 105.596 94.7718 105.634 94.1383C105.672 93.5047 105.927 92.9035 106.356 92.4354C106.784 91.9673 107.361 91.6609 107.989 91.5674C108.617 91.4739 109.257 91.5992 109.804 91.9221C110.35 92.245 110.769 92.746 110.99 93.341L109.26 94.7478L111.166 94.304C111.165 94.7447 111.059 95.1788 110.856 95.57Z"
        fill="#005CFF"
      />
      <path
        d="M109.489 90.1923C109.126 90.2094 108.763 90.1547 108.421 90.0314C108.079 89.9082 107.765 89.7188 107.496 89.474C107.227 89.2292 107.009 88.9339 106.854 88.6049C106.7 88.2759 106.611 87.9196 106.594 87.5565C106.573 87.1229 106.655 86.6904 106.833 86.2948C106.999 85.925 107.243 85.5959 107.549 85.3308C107.856 85.0656 108.216 84.8707 108.606 84.7599L108.59 85.8979L109.217 84.6575C109.951 84.6232 110.668 84.8816 111.211 85.3759C111.754 85.8702 112.078 86.5599 112.112 87.2933C112.147 88.0267 111.888 88.7437 111.394 89.2866C110.899 89.8295 110.21 90.1537 109.476 90.1881L109.489 90.1923Z"
        fill="#005CFF"
      />
      <path
        d="M116.209 85.7207C115.842 85.7451 115.473 85.696 115.125 85.5762C114.776 85.4564 114.456 85.2684 114.181 85.0232C113.906 84.7779 113.683 84.4803 113.525 84.1478C113.367 83.8153 113.276 83.4546 113.259 83.0867C113.242 82.7189 113.298 82.3513 113.425 82.0054C113.551 81.6596 113.745 81.3425 113.996 81.0727C114.247 80.8028 114.548 80.5856 114.884 80.4339C115.22 80.2821 115.582 80.1988 115.95 80.1887C116.467 80.1635 116.981 80.2854 117.432 80.5401L116.943 81.8801L117.95 80.9142C118.491 81.4069 118.813 82.094 118.848 82.8246C118.86 83.0808 118.836 83.3375 118.777 83.587C118.639 84.1735 118.314 84.6992 117.85 85.0842C117.387 85.4693 116.811 85.6927 116.209 85.7207Z"
        fill="#005CFF"
      />
      <path
        opacity="0.1"
        d="M115.419 77.5689C114.781 77.6386 114.16 77.8378 113.519 77.8776C112.877 77.9174 112.173 77.7638 111.768 77.2645C111.549 76.9971 111.441 76.6571 111.256 76.3641C111.081 76.0913 110.842 75.866 110.559 75.7083C110.381 76.0592 110.28 76.4439 110.263 76.8368C110.246 77.2297 110.312 77.6218 110.458 77.987C110.604 78.3522 110.826 78.682 111.11 78.9546C111.394 79.2271 111.732 79.4361 112.103 79.5677C112.473 79.6992 112.868 79.7502 113.26 79.7174C113.651 79.6845 114.032 79.5685 114.375 79.3771C114.719 79.1857 115.018 78.9233 115.252 78.6073C115.486 78.2914 115.65 77.9291 115.734 77.5447C115.628 77.5504 115.525 77.5575 115.419 77.5689Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M116.209 85.7214C115.732 85.7431 115.257 85.6412 114.831 85.4255C114.405 85.2099 114.042 84.8878 113.777 84.4905C113.512 84.0932 113.355 83.6342 113.319 83.158C113.284 82.6818 113.372 82.2046 113.576 81.7726C113.795 81.9212 113.984 82.1105 114.132 82.3302C114.33 82.6275 114.449 82.9717 114.681 83.2448C115.108 83.7527 115.849 83.9177 116.516 83.8878C117.183 83.8579 117.829 83.6673 118.495 83.6033C118.587 83.5948 118.681 83.5891 118.779 83.5862C118.642 84.1735 118.316 84.6997 117.852 85.0851C117.388 85.4705 116.811 85.6939 116.209 85.7214Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M109.489 90.1917C109.126 90.2087 108.763 90.1541 108.421 90.0308C108.079 89.9075 107.765 89.7181 107.496 89.4734C107.227 89.2286 107.009 88.9333 106.854 88.6043C106.7 88.2753 106.611 87.919 106.594 87.5559C106.573 87.1223 106.655 86.6898 106.833 86.2941C107.062 86.4462 107.26 86.6391 107.418 86.8631C107.633 87.1661 107.768 87.516 108.02 87.7963C108.486 88.3155 109.271 88.499 109.978 88.4819C110.685 88.4648 111.324 88.2998 112.005 88.2472C111.837 88.7905 111.504 89.2687 111.054 89.6166C110.604 89.9645 110.058 90.1653 109.489 90.1917Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M110.856 95.5705C110.534 96.2035 109.981 96.6885 109.311 96.9251C108.641 97.1617 107.906 97.1319 107.258 96.8417C106.61 96.5516 106.098 96.0233 105.828 95.3662C105.558 94.7091 105.551 93.9735 105.809 93.3116C106.037 93.4615 106.242 93.6441 106.416 93.8535C106.68 94.175 106.853 94.5392 107.157 94.8379C107.726 95.3927 108.637 95.6217 109.451 95.643C109.92 95.6469 110.389 95.6226 110.856 95.5705Z"
        fill="black"
      />
      <path
        d="M150.934 100.757C151.52 99.6699 150.855 98.3385 150.089 97.3684C149.324 96.3982 148.391 95.3911 148.411 94.155C148.439 92.3812 150.323 91.3328 151.825 90.3911C152.942 89.6932 153.972 88.8653 154.894 87.9246C155.28 87.5622 155.6 87.1346 155.837 86.6614C156.149 85.9673 156.139 85.1778 156.122 84.4182C156.057 81.8843 155.931 79.3542 155.746 76.8279"
        stroke="#3F3D56"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M158.608 76.6882C158.587 76.2247 158.449 75.774 158.208 75.3778C157.966 74.9815 157.629 74.6524 157.227 74.4208L156.607 75.6469L156.625 74.1619C156.33 74.0755 156.021 74.0384 155.714 74.0524C155.167 74.078 154.64 74.2652 154.199 74.5903C153.759 74.9155 153.425 75.3639 153.239 75.879C153.053 76.394 153.025 76.9525 153.156 77.4839C153.288 78.0152 153.575 78.4956 153.98 78.8642C154.384 79.2328 154.889 79.4731 155.431 79.5547C155.972 79.6362 156.525 79.5555 157.021 79.3226C157.516 79.0897 157.932 78.7151 158.214 78.2461C158.497 77.7772 158.634 77.2351 158.608 76.6882Z"
        fill="#005CFF"
      />
      <path
        d="M153.672 95.4282C153.382 95.9926 152.906 96.4399 152.325 96.6952C151.744 96.9505 151.093 96.9982 150.481 96.8304C149.869 96.6625 149.333 96.2894 148.963 95.7734C148.594 95.2574 148.413 94.6301 148.451 93.9965C148.489 93.3629 148.743 92.7617 149.172 92.2936C149.601 91.8255 150.177 91.5191 150.805 91.4256C151.433 91.3322 152.074 91.4574 152.62 91.7803C153.166 92.1032 153.585 92.6042 153.806 93.1992L152.076 94.6061L153.983 94.1622C153.982 94.6029 153.875 95.0371 153.672 95.4282Z"
        fill="#005CFF"
      />
      <path
        d="M152.305 90.05C151.942 90.067 151.579 90.0124 151.237 89.8891C150.895 89.7658 150.581 89.5764 150.312 89.3317C150.043 89.0869 149.825 88.7916 149.671 88.4626C149.516 88.1336 149.428 87.7773 149.411 87.4142C149.389 86.9806 149.471 86.5481 149.65 86.1524C149.815 85.7826 150.059 85.4536 150.366 85.1884C150.672 84.9233 151.032 84.7284 151.422 84.6176L151.406 85.7556L152.034 84.5152H152.041C152.404 84.4982 152.767 84.5529 153.109 84.6761C153.451 84.7994 153.765 84.9888 154.034 85.2336C154.303 85.4783 154.521 85.7736 154.676 86.1026C154.83 86.4316 154.919 86.7879 154.936 87.151C154.953 87.5142 154.898 87.8771 154.775 88.2191C154.651 88.5611 154.462 88.8755 154.217 89.1443C153.972 89.4131 153.677 89.631 153.348 89.7857C153.019 89.9404 152.663 90.0287 152.3 90.0457L152.305 90.05Z"
        fill="#005CFF"
      />
      <path
        d="M159.025 85.5789C158.658 85.6033 158.289 85.5542 157.941 85.4344C157.593 85.3147 157.272 85.1266 156.997 84.8814C156.722 84.6361 156.499 84.3385 156.341 84.006C156.183 83.6735 156.092 83.3128 156.075 82.9449C156.058 82.5771 156.114 82.2095 156.241 81.8637C156.367 81.5178 156.562 81.2007 156.812 80.9309C157.063 80.661 157.365 80.4439 157.7 80.2921C158.036 80.1403 158.398 80.057 158.766 80.0469C159.284 80.0218 159.798 80.1436 160.249 80.3983L159.759 81.7383L160.766 80.7724C161.307 81.2651 161.63 81.9522 161.664 82.6828C161.677 82.939 161.653 83.1957 161.593 83.4452C161.455 84.0317 161.13 84.5574 160.667 84.9424C160.203 85.3275 159.627 85.5509 159.025 85.5789Z"
        fill="#005CFF"
      />
      <path
        opacity="0.1"
        d="M158.236 77.4266C157.597 77.4963 156.977 77.6954 156.335 77.7352C155.694 77.7751 154.99 77.6214 154.584 77.1222C154.365 76.8547 154.257 76.5148 154.072 76.2217C153.897 75.949 153.658 75.7237 153.375 75.566C153.197 75.9169 153.097 76.3016 153.079 76.6945C153.062 77.0874 153.128 77.4795 153.274 77.8447C153.421 78.2098 153.643 78.5397 153.926 78.8122C154.21 79.0848 154.548 79.2938 154.919 79.4253C155.29 79.5569 155.684 79.6079 156.076 79.575C156.468 79.5422 156.848 79.4262 157.192 79.2348C157.535 79.0434 157.834 78.7809 158.068 78.465C158.303 78.149 158.467 77.7868 158.55 77.4024C158.445 77.4081 158.341 77.4152 158.236 77.4266Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M159.025 85.5785C158.548 85.6002 158.074 85.4983 157.648 85.2827C157.222 85.067 156.858 84.7449 156.594 84.3476C156.329 83.9503 156.171 83.4913 156.136 83.0151C156.1 82.5389 156.189 82.0617 156.392 81.6297C156.611 81.7783 156.8 81.9676 156.948 82.1873C157.146 82.4846 157.266 82.8288 157.497 83.102C157.924 83.6098 158.665 83.7748 159.332 83.7449C160 83.715 160.645 83.5244 161.311 83.4604C161.404 83.4519 161.497 83.4462 161.596 83.4433C161.458 84.0306 161.133 84.5569 160.669 84.9422C160.205 85.3276 159.628 85.551 159.025 85.5785Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M152.305 90.0494C151.942 90.0664 151.579 90.0117 151.237 89.8885C150.895 89.7652 150.581 89.5758 150.312 89.331C150.043 89.0863 149.825 88.7909 149.671 88.4619C149.516 88.1329 149.428 87.7767 149.411 87.4135C149.389 86.9799 149.471 86.5475 149.65 86.1518C149.878 86.3039 150.076 86.4967 150.234 86.7208C150.449 87.0238 150.584 87.3737 150.836 87.6539C151.303 88.1731 152.088 88.3566 152.795 88.3396C153.48 88.3253 154.14 88.1575 154.822 88.1049C154.653 88.6482 154.321 89.1263 153.871 89.4743C153.42 89.8222 152.874 90.023 152.305 90.0494Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M153.672 95.4281C153.35 96.0612 152.797 96.5462 152.128 96.7828C151.458 97.0194 150.723 96.9896 150.075 96.6994C149.426 96.4092 148.914 95.881 148.645 95.2239C148.375 94.5668 148.368 93.8312 148.626 93.1693C148.853 93.3192 149.058 93.5018 149.233 93.7112C149.496 94.0327 149.67 94.3968 149.974 94.6956C150.543 95.2503 151.453 95.4793 152.267 95.5007C152.737 95.5045 153.206 95.4803 153.672 95.4281Z"
        fill="black"
      />
      <path
        d="M15.6374 98.9073C15.0513 97.8206 15.717 96.4891 16.4823 95.519C17.2476 94.5489 18.1807 93.5418 18.1608 92.3056C18.1324 90.5318 16.249 89.4835 14.7469 88.5418C13.6297 87.8439 12.5993 87.0159 11.6772 86.0752C11.2911 85.7128 10.9718 85.2852 10.7341 84.8121C10.4226 84.1179 10.4326 83.3284 10.4496 82.5688C10.5151 80.0349 10.6402 77.5048 10.8252 74.9785"
        stroke="#3F3D56"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M7.96298 74.8394C7.98438 74.3759 8.12212 73.9252 8.3635 73.529C8.60489 73.1327 8.94215 72.8036 9.3442 72.572L9.9644 73.7981L9.9459 72.3131C10.2417 72.2266 10.5498 72.1896 10.8577 72.2035C11.4046 72.2292 11.9316 72.4164 12.372 72.7415C12.8125 73.0667 13.1467 73.5151 13.3323 74.0301C13.5179 74.5452 13.5466 75.1037 13.4148 75.6351C13.283 76.1664 12.9966 76.6468 12.5918 77.0154C12.187 77.384 11.682 77.6243 11.1406 77.7058C10.5993 77.7874 10.0459 77.7067 9.55043 77.4738C9.05498 77.2408 8.63972 76.8662 8.35715 76.3973C8.07459 75.9284 7.93742 75.3862 7.96298 74.8394Z"
        fill="#005CFF"
      />
      <path
        d="M12.8991 93.5789C13.1896 94.1432 13.6653 94.5905 14.2464 94.8458C14.8275 95.1011 15.4787 95.1489 16.0908 94.981C16.7029 94.8132 17.2387 94.44 17.6083 93.924C17.9779 93.4081 18.1589 92.7807 18.1209 92.1471C18.0829 91.5136 17.8282 90.9123 17.3996 90.4442C16.9709 89.9762 16.3943 89.6697 15.7665 89.5762C15.1388 89.4828 14.4979 89.608 13.9515 89.9309C13.4051 90.2539 12.9863 90.7548 12.7654 91.3499L14.4952 92.7567L12.5891 92.3129C12.5898 92.7536 12.6962 93.1877 12.8991 93.5789Z"
        fill="#005CFF"
      />
      <path
        d="M14.2661 88.2006C14.6292 88.2177 14.9922 88.163 15.3342 88.0397C15.6762 87.9165 15.9906 87.7271 16.2594 87.4823C16.5282 87.2375 16.7462 86.9422 16.9008 86.6132C17.0555 86.2842 17.1438 85.9279 17.1608 85.5648C17.1823 85.1312 17.1004 84.6987 16.9218 84.3031C16.7565 83.9333 16.5121 83.6042 16.2059 83.3391C15.8997 83.0739 15.5391 82.879 15.1494 82.7682L15.1651 83.9062L14.5378 82.6658C13.8044 82.6315 13.0874 82.8899 12.5445 83.3842C12.0016 83.8785 11.6774 84.5682 11.643 85.3016C11.6087 86.035 11.8671 86.752 12.3614 87.2949C12.8558 87.8378 13.5455 88.162 14.2789 88.1964L14.2661 88.2006Z"
        fill="#005CFF"
      />
      <path
        d="M7.54621 83.7299C7.91366 83.7543 8.28228 83.7051 8.63052 83.5854C8.97875 83.4656 9.2996 83.2776 9.5743 83.0323C9.84899 82.787 10.072 82.4895 10.2303 82.157C10.3886 81.8245 10.479 81.4637 10.4962 81.0959C10.5135 80.728 10.4571 80.3604 10.3306 80.0146C10.204 79.6688 10.0098 79.3517 9.75918 79.0818C9.50859 78.812 9.2067 78.5948 8.87117 78.443C8.53565 78.2912 8.17322 78.2079 7.8051 78.1979C7.29015 78.174 6.77891 78.2958 6.33 78.5492L6.81933 79.8892L5.80511 78.9276C5.2656 79.4193 4.94286 80.1047 4.90753 80.8337C4.89493 81.09 4.91888 81.3467 4.97865 81.5962C5.11631 82.1827 5.44147 82.7083 5.90481 83.0934C6.36815 83.4784 6.94441 83.7019 7.54621 83.7299Z"
        fill="#005CFF"
      />
      <path
        opacity="0.1"
        d="M8.33564 75.5772C8.97433 75.6469 9.59452 75.8461 10.2361 75.8859C10.8776 75.9257 11.5817 75.7721 11.9871 75.2728C12.2062 75.0054 12.3143 74.6654 12.4992 74.3724C12.6738 74.0996 12.9133 73.8743 13.1962 73.7166C13.3739 74.0675 13.4748 74.4522 13.4921 74.8451C13.5095 75.238 13.4429 75.6301 13.2968 75.9953C13.1508 76.3605 12.9286 76.6903 12.645 76.9629C12.3615 77.2354 12.0231 77.4444 11.6525 77.576C11.2818 77.7075 10.8874 77.7585 10.4955 77.7257C10.1035 77.6928 9.72311 77.5768 9.37953 77.3854C9.03595 77.194 8.7371 76.9316 8.50289 76.6156C8.26869 76.2997 8.1045 75.9374 8.02127 75.553C8.12653 75.5587 8.23037 75.5658 8.33564 75.5772Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M7.54639 83.7297C8.0234 83.7515 8.49794 83.6496 8.92397 83.4339C9.35001 83.2183 9.71309 82.8962 9.97799 82.4989C10.2429 82.1016 10.4006 81.6426 10.4359 81.1664C10.4712 80.6902 10.3828 80.2129 10.1794 79.7809C9.96007 79.9296 9.77127 80.1189 9.6232 80.3386C9.42547 80.6358 9.30598 80.9801 9.07412 81.2532C8.64738 81.761 7.90627 81.926 7.23913 81.8962C6.57199 81.8663 5.92619 81.6757 5.26048 81.6117C5.16802 81.6031 5.07413 81.5974 4.97598 81.5946C5.11351 82.1818 5.43897 82.7081 5.90291 83.0935C6.36684 83.4788 6.9439 83.7022 7.54639 83.7297Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M14.2659 88.2006C14.629 88.2176 14.992 88.1629 15.334 88.0396C15.676 87.9164 15.9904 87.727 16.2592 87.4822C16.528 87.2374 16.7459 86.9421 16.9006 86.6131C17.0553 86.2841 17.1436 85.9279 17.1606 85.5647C17.1821 85.1311 17.1002 84.6987 16.9216 84.303C16.6934 84.455 16.4952 84.6479 16.337 84.872C16.1222 85.175 15.9871 85.5249 15.7353 85.8051C15.2687 86.3243 14.4835 86.5078 13.7765 86.4907C13.0909 86.4765 12.4309 86.3087 11.7495 86.256C11.9184 86.7994 12.2505 87.2775 12.7008 87.6255C13.151 87.9734 13.6975 88.1742 14.2659 88.2006Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M12.8992 93.5788C13.2213 94.2118 13.7744 94.6968 14.4441 94.9334C15.1138 95.17 15.8489 95.1402 16.4972 94.85C17.1455 94.5599 17.6574 94.0316 17.9271 93.3745C18.1968 92.7174 18.2036 91.9818 17.9461 91.3199C17.7182 91.4698 17.5136 91.6524 17.3387 91.8618C17.0756 92.1833 16.902 92.5475 16.5976 92.8462C16.0286 93.401 15.1183 93.63 14.3046 93.6513C13.8351 93.6552 13.3658 93.6309 12.8992 93.5788Z"
        fill="black"
      />
      <path
        d="M44.6372 94.7667L46.532 93.6529C46.2759 93.2152 45.9485 92.8234 45.5633 92.4936L41.99 93.6045L44.7895 91.9587C44.0962 91.5791 43.3161 91.3865 42.5259 91.3999C41.7357 91.4134 40.9625 91.6323 40.2826 92.0352C39.6027 92.4381 39.0394 93.0111 38.6481 93.6978C38.2568 94.3845 38.0511 95.1613 38.0512 95.9516C38.0512 98.4651 40.0896 98.5519 42.6031 98.5519C45.1166 98.5519 47.155 98.4651 47.155 95.9516C47.1565 95.3145 47.0232 94.6843 46.7638 94.1024L44.6372 94.7667Z"
        fill="#005CFF"
      />
      <path
        opacity="0.1"
        d="M45.1195 97.2103C44.9177 97.008 44.6922 96.8308 44.4481 96.6825C44.1052 96.4535 43.7482 96.2202 43.3428 96.1434C42.9872 96.0751 42.6202 96.1334 42.2575 96.1121C41.6276 96.0737 41.0347 95.8018 40.5946 95.3497C40.3357 95.0822 40.1323 94.7536 39.8208 94.5488C39.4726 94.3419 39.0618 94.2667 38.6629 94.3369C38.5473 94.353 38.4328 94.3762 38.3201 94.4066C38.1418 94.9021 38.0508 95.4248 38.0512 95.9514C38.0512 98.4649 40.0896 98.5516 42.6031 98.5516C43.8236 98.5516 44.9303 98.5317 45.7482 98.2231L45.606 97.9684C45.4767 97.696 45.3133 97.4412 45.1195 97.2103Z"
        fill="black"
      />
      <path d="M148.211 0H147.798V3.81332H148.211V0Z" fill="#F2F2F2" />
      <path
        d="M160.389 55.2416H159.977V59.0549H160.389V55.2416Z"
        fill="#F2F2F2"
      />
      <path
        d="M172.981 55.2416H172.568V59.0549H172.981V55.2416Z"
        fill="#F2F2F2"
      />
      <path
        d="M164.724 32.877H164.311V36.6903H164.724V32.877Z"
        fill="#F2F2F2"
      />
      <path
        d="M177.65 56.4742V61.5809C177.65 61.5809 178.881 69.8084 178.881 76.9956C178.881 84.1829 176.892 100.922 176.892 100.922C176.892 100.922 172.914 102.34 172.63 100.165C172.346 97.99 171.589 86.4525 171.589 84.7503C171.589 83.0481 168.274 70.0921 168.274 70.0921L166.19 84.4666C166.19 84.4666 166.853 101.489 166.19 101.962C165.527 102.435 162.307 103.286 161.929 102.435C161.55 101.584 159.561 83.3318 159.561 83.3318C159.561 83.3318 157.951 59.5004 158.898 58.0818C159.845 56.6633 160.698 55.5285 160.698 55.5285C160.698 55.5285 174.24 52.2186 177.65 56.4742Z"
        fill="#2F2E41"
      />
      <path
        d="M176.419 100.354L176.656 101.895C176.736 102.421 176.788 102.951 176.811 103.482C176.848 104.257 176.977 105.45 177.419 105.639C178.082 105.923 182.385 109.546 179.355 109.736C176.324 109.925 174.808 110.251 173.91 109.555C172.849 108.733 174.051 109.105 173.578 109.105C173.104 109.105 172.536 109.105 172.346 108.349C172.157 107.592 172.631 106.268 172.631 106.268V100.354H176.419Z"
        fill="#2F2E41"
      />
      <path
        d="M162.278 100.28L162.04 101.821C161.961 102.347 161.909 102.877 161.885 103.408C161.848 104.182 161.719 105.376 161.277 105.565C160.614 105.848 156.312 109.472 159.342 109.661C162.373 109.85 163.889 110.177 164.786 109.481C165.848 108.658 164.646 109.031 165.119 109.031C165.593 109.031 166.161 109.031 166.35 108.274C166.54 107.518 166.066 106.194 166.066 106.194V100.28H162.278Z"
        fill="#2F2E41"
      />
      <path
        d="M164.959 15.4309C164.959 15.4309 166.569 19.4028 167.706 20.0648C168.842 20.7268 168.274 23.753 168.274 23.753L165.433 27.7249H163.16L160.414 22.9964C160.414 22.9964 160.224 19.8757 159.656 19.1191C159.088 18.3626 164.959 15.4309 164.959 15.4309Z"
        fill="#FFB9B9"
      />
      <path
        opacity="0.1"
        d="M164.959 15.4309C164.959 15.4309 166.569 19.4028 167.706 20.0648C168.842 20.7268 168.274 23.753 168.274 23.753L165.433 27.7249H163.16L160.414 22.9964C160.414 22.9964 160.224 19.8757 159.656 19.1191C159.088 18.3626 164.959 15.4309 164.959 15.4309Z"
        fill="black"
      />
      <path
        d="M167.327 23.5641L171.589 24.5098L174.714 55.9067C174.714 55.9067 173.009 56.1904 171.305 58.46C169.6 60.7297 167.516 60.3514 166.475 59.784C165.433 59.2166 161.266 57.6089 161.171 56.9469C161.077 56.2849 161.361 44.653 161.361 44.653L158.709 26.4957L160.035 24.5098H162.402L164.297 26.1175L167.327 23.5641Z"
        fill="#005CFF"
      />
      <path
        d="M160.887 20.3487C163.607 20.3487 165.812 18.147 165.812 15.4311C165.812 12.7152 163.607 10.5135 160.887 10.5135C158.167 10.5135 155.962 12.7152 155.962 15.4311C155.962 18.147 158.167 20.3487 160.887 20.3487Z"
        fill="#FFB9B9"
      />
      <path
        d="M167.99 21.9562C167.99 21.9562 177.271 22.9019 178.123 24.8878C178.976 26.8738 177.555 35.6687 177.555 35.6687C177.555 35.6687 180.964 43.3288 180.87 45.1256C180.775 46.9224 184.09 59.2163 180.491 57.7978C176.892 56.3793 178.029 52.6911 176.229 55.339C174.43 57.9869 173.672 57.6087 173.672 57.6087C173.672 57.6087 171.873 60.5403 171.305 60.2566C170.736 59.9729 170.358 57.7978 171.589 56.7575C172.82 55.7173 172.63 53.9205 170.263 50.516C167.895 47.1115 165.433 29.238 166.853 26.4955C168.274 23.753 167.99 21.9562 167.99 21.9562Z"
        fill="#575A89"
      />
      <path
        d="M177.65 58.2702L176.04 59.7833L174.051 58.4594L174.903 56.4734L177.65 58.2702Z"
        fill="#FFB9B9"
      />
      <path
        d="M161.171 23.5641C161.171 23.5641 153.974 26.212 153.974 29.0491C153.974 31.8862 158.33 44.2747 158.33 44.2747C158.33 44.2747 158.33 47.49 158.141 48.2466C157.951 49.0031 154.971 55.6017 157.572 56.1904C159.196 56.5578 160.53 57.5232 160.65 58.8856C160.793 60.5034 159.553 62.5454 159.656 62.9048C159.845 63.5667 160.982 64.5124 160.887 64.0396C160.792 63.5667 161.645 56.7578 162.971 53.7316C164.296 50.7054 162.402 30.3731 162.024 27.7251C161.841 26.3193 161.556 24.9287 161.171 23.5641Z"
        fill="#575A89"
      />
      <path
        d="M157.383 58.0813L159.088 62.148L160.366 59.5473L158.898 56.2845L157.383 58.0813Z"
        fill="#FFB9B9"
      />
      <path
        d="M166.435 18.4775C166.435 18.4775 167.99 19.1191 168.937 19.1191C169.884 19.1191 173.767 21.105 172.441 22.3344C171.115 23.5638 169.126 23.8475 168.179 25.6443C167.232 27.4411 166.759 27.7249 166.759 27.7249L165.073 25.4632C165.073 25.4632 167.422 22.8073 167.327 21.9562C167.232 21.105 166.435 18.4775 166.435 18.4775Z"
        fill="#005CFF"
      />
      <path
        d="M160.158 20.7517C160.158 20.7517 158.993 21.2 158.709 21.9565C158.425 22.7131 156.625 24.1316 157.383 24.5099C158.141 24.8882 161.455 26.4013 162.118 26.685C162.781 26.9687 164.017 25.8801 164.017 25.8801C164.017 25.8801 162.355 24.9355 162.355 24.2735C162.355 23.6115 160.158 20.7517 160.158 20.7517Z"
        fill="#005CFF"
      />
      <path
        d="M156.247 27.7248L153.974 28.2922C153.974 28.2922 150.848 42.2884 151.796 45.7875C152.743 49.2865 157.194 59.7837 157.194 59.7837L160.035 56.4738C160.035 56.4738 158.795 48.9471 158.089 48.4548C157.383 47.9625 157.857 46.9223 157.857 46.6386C157.857 46.3549 158.141 46.544 157.478 45.882C156.815 45.2201 159.94 41.5319 159.088 40.4916C158.235 39.4513 156.247 27.7248 156.247 27.7248Z"
        fill="#575A89"
      />
      <path
        opacity="0.1"
        d="M178.644 30.3254L177.271 25.1714C177.271 25.1714 180.964 27.8194 181.722 31.6967C182.479 35.574 185.415 43.3287 183.521 46.7332C181.627 50.1377 178.312 59.9728 177.176 59.7837C176.04 59.5945 173.293 57.2303 173.198 56.852C173.104 56.4738 178.123 47.7734 177.365 47.1114C176.608 46.4495 176.797 44.7472 177.365 44.3689C177.934 43.9907 178.597 42.1939 178.123 42.2884C177.65 42.383 176.229 41.4373 176.513 40.3971C176.797 39.3568 175.093 32.3587 175.093 32.3587L178.644 30.3254Z"
        fill="black"
      />
      <path
        d="M176.797 24.5095L178.123 24.8878C178.123 24.8878 181.817 27.5357 182.574 31.4131C183.332 35.2904 186.268 43.045 184.374 46.4495C182.48 49.854 179.165 59.6892 178.028 59.5C176.892 59.3109 174.146 56.9467 174.051 56.5684C173.956 56.1901 178.975 47.4898 178.218 46.8278C177.46 46.1658 177.65 44.4636 178.218 44.0853C178.786 43.707 179.449 41.9102 178.975 42.0048C178.502 42.0994 177.081 41.1537 177.365 40.1134C177.65 39.0731 175.945 32.075 175.945 32.075L176.797 24.5095Z"
        fill="#575A89"
      />
      <path
        d="M164.709 10.9922L165.632 10.6569C165.632 10.6569 163.701 8.72835 161.014 8.89606L161.77 8.14142C161.77 8.14142 159.923 7.47065 158.243 9.23144C157.36 10.1571 156.339 11.2451 155.702 12.4707H154.713L155.126 13.2952L153.681 14.1197L155.164 13.9716C155.024 14.6834 155.072 15.4196 155.304 16.107L155.64 17.0293C155.64 17.0293 156.984 14.3461 156.984 14.0108V14.8492C156.984 14.8492 157.907 14.0946 157.907 13.5915L158.411 14.1785L158.663 13.2561L161.77 14.1785L161.266 13.4238L163.197 13.6754L162.442 12.7531C162.442 12.7531 164.625 13.8431 164.709 14.7654C164.793 15.6877 165.884 16.8616 165.884 16.8616L166.388 16.0231C166.388 16.0231 167.144 12.25 164.709 10.9922Z"
        fill="#2F2E41"
      />
      <path
        d="M164.518 15.9748C164.917 15.9748 165.241 15.4673 165.241 14.8411C165.241 14.215 164.917 13.7075 164.518 13.7075C164.119 13.7075 163.796 14.215 163.796 14.8411C163.796 15.4673 164.119 15.9748 164.518 15.9748Z"
        fill="#FFB9B9"
      />
    </svg>
  );
};
