export const MullbryLogo = (): JSX.Element => {
  return (
    <svg
      width="114"
      height="42"
      viewBox="0 0 114 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.849792 20.1497H4.40452V22.2805C5.02893 20.8668 6.47725 19.8655 8.45411 19.8655C10.5268 19.8655 11.9363 20.8079 12.6488 22.4546C13.5737 20.603 15.3874 19.8655 17.5715 19.8655C20.8749 19.8655 22.7818 22.1704 22.7818 25.8095V34.9906H19.1857V26.3012C19.1857 24.2985 18.2322 23.0052 16.5067 23.0052C14.7811 23.0052 13.5297 24.4163 13.5297 26.4472V34.9803H9.98793V26.3012C9.98793 24.2985 9.03447 23.0052 7.30893 23.0052C5.64297 23.0052 4.40452 24.4163 4.40452 26.4472V34.9803H0.849792V20.1497Z"
        fill="black"
      />
      <path
        d="M27.5492 20.1498H31.1506V28.8007C31.1506 31.1261 32.5496 32.13 34.4255 32.13C36.3013 32.13 37.7004 31.1312 37.7004 28.7777V20.1498H41.3017V29.0056C41.3017 33.3029 38.146 35.362 34.3944 35.362C30.6427 35.362 27.5492 33.3311 27.5492 29.0953V20.1498Z"
        fill="black"
      />
      <path
        d="M46.183 14.472H49.7455V30.1247C49.7455 31.5666 50.2222 32.1735 51.5332 32.1735C51.8935 32.1787 52.253 32.1391 52.6033 32.0557V35.2057C52.0479 35.333 51.4788 35.3915 50.9088 35.3798C48.9734 35.3798 46.1752 34.8523 46.1752 30.5857L46.183 14.472Z"
        fill="black"
      />
      <path
        d="M56.3342 14.472H59.9044V30.1247C59.9044 31.5666 60.3812 32.1735 61.6896 32.1735C62.0507 32.1788 62.4111 32.1392 62.7622 32.0557V35.2057C62.206 35.3328 61.636 35.3913 61.0652 35.3798C59.1324 35.3798 56.3342 34.8523 56.3342 30.5857V14.472Z"
        fill="black"
      />
      <path
        d="M66.5424 14.472H70.1152V22.2984C70.6204 20.9155 72.3771 19.827 74.9084 19.827C79.1342 19.827 82.4091 23.1819 82.4091 27.5638C82.4091 31.9456 79.1342 35.303 74.9084 35.303C72.3771 35.303 70.6204 34.2146 70.1152 32.8317V34.9803H66.5424V14.472ZM74.4317 32.0967C76.9604 32.0967 78.8647 30.1555 78.8647 27.5638C78.8647 24.972 76.95 23.0334 74.4317 23.0334C73.8204 23.019 73.2125 23.1256 72.6435 23.3469C72.0746 23.5682 71.5562 23.8997 71.1187 24.3219C70.6813 24.7441 70.3337 25.2486 70.0964 25.8055C69.859 26.3625 69.7368 26.9607 69.7368 27.565C69.7368 28.1694 69.859 28.7676 70.0964 29.3246C70.3337 29.8815 70.6813 30.3859 71.1187 30.8082C71.5562 31.2304 72.0746 31.5619 72.6435 31.7832C73.2125 32.0045 73.8204 32.111 74.4317 32.0967Z"
        fill="black"
      />
      <path
        d="M85.3912 20.1497H88.933V22.3573C89.6481 20.8207 91.2648 19.8552 93.3997 19.8552C93.7582 19.8592 94.1158 19.8892 94.4698 19.9449V23.3586C93.9808 23.2573 93.4822 23.2076 92.9826 23.2101C90.5109 23.2101 88.933 24.7467 88.933 27.1822V34.9803H85.3912V20.1497Z"
        fill="black"
      />
      <path
        d="M101.196 38.1867C101.656 38.2139 102.113 38.1013 102.507 37.864C102.836 37.6566 103.131 37.2161 103.37 36.5989L103.758 35.5387L97.4752 20.1498H101.079L105.484 31.331L109.629 20.1498H113.223L106.486 37.3903C106.155 38.3799 105.606 39.2843 104.877 40.0383C103.927 40.9193 102.706 41.2727 101.276 41.2727C100.682 41.2775 100.091 41.1982 99.5194 41.0371V37.9511C100.062 38.1186 100.628 38.1982 101.196 38.1867Z"
        fill="black"
      />
      <path
        d="M86.197 15.0252L58.1323 0.555664L30.0443 15.038L39.6565 15.0585L58.1323 5.5342L76.5485 15.0303L86.197 15.0252Z"
        fill="#005CFF"
      />
      <path
        d="M101.126 15.0227L75.2581 1.68518L70.4468 4.16933L91.4798 15.0279L101.126 15.0227Z"
        fill="#005CFF"
      />
    </svg>
  );
};
