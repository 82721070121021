import { BrowserRouter, Navigate, Route } from 'react-router-dom';

import {
  MullbryESignConsentAgreementPdf,
  MullbryGlbaPrivacyPolicyPdf,
  MullbryInformationSharingDisclosurePdf,
} from 'common/constants';
import { TriadTransferPage } from 'components/triad-transfer/triad-transfer';
import { SentryRoutes } from 'index';

import { AboutPage } from './components/about/about-page';
import { DocumentWrapper } from './components/common/document-wrapper';
import { ScrollToTop } from './components/common/scroll-to-top';
import { ContactPage } from './components/contact/contact-page';
import { DocumentsPage } from './components/documents/documents-page';
import { HowLoanProcessWorksPage } from './components/how-loan-process-works/how-loan-process-works-page';
import { LandingPage } from './components/landing/landing-page';
import { TermsOfUsePage } from './components/terms-of-use/terms-of-use-page';
import { AppRoutes } from './routes/routes.enums';

export const App = (): JSX.Element => {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <SentryRoutes>
          <Route
            path={AppRoutes.HowItWorks}
            element={<HowLoanProcessWorksPage />}
          />
          <Route path={AppRoutes.About} element={<AboutPage />} />
          <Route path={AppRoutes.Contact} element={<ContactPage />} />
          <Route path={AppRoutes.Documents} element={<DocumentsPage />} />
          <Route
            path={AppRoutes.ESignConsentAgreement}
            element={
              <DocumentWrapper
                documentFileLocation={MullbryESignConsentAgreementPdf}
                documentTitle={'E-Sign Consent Agreement'}
                documentDownloadName={'Mullbry E-Sign Consent Agreement'}
              />
            }
          />
          <Route
            path={AppRoutes.TriadTransfer}
            element={<TriadTransferPage />}
          />
          <Route
            path={AppRoutes.GLBAPrivacyPolicy}
            element={
              <DocumentWrapper
                documentFileLocation={MullbryGlbaPrivacyPolicyPdf}
                documentTitle={'Privacy Policy'}
                documentDownloadName={'Mullbry GLBA Privacy Policy'}
              />
            }
          />
          <Route
            path={AppRoutes.InformationSharingDisclosure}
            element={
              <DocumentWrapper
                documentFileLocation={MullbryInformationSharingDisclosurePdf}
                documentTitle={'Information Sharing Disclosure and Consent'}
                documentDownloadName={
                  'Mullbry Information Sharing Disclosure and Consent'
                }
              />
            }
          />
          <Route path={AppRoutes.TermsOfUse} element={<TermsOfUsePage />} />
          <Route path={AppRoutes.Home} element={<LandingPage />} />
          <Route path="*" element={<Navigate replace to={AppRoutes.Home} />} />
        </SentryRoutes>
      </BrowserRouter>
    </>
  );
};
