export enum HomeType {
  New = 'New',
  Used = 'Used',
  ReallyUsed = 'ReallyUsed',
}

export enum CreditScoreCategory {
  Under600 = '>600',
  SixHundredToFiveFifty = '600-650',
  SixFiftyOneToSevenHundred = '651-700',
  SevenHundredToSevenFifty = '701-750',
  OverSevenFifty = '750+',
}
