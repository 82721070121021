import {
  Invested,
  PhoneApp,
  TransparentPage,
  Workers,
} from 'components/common/svg';

export const WhyMullbry = (): JSX.Element => {
  return (
    <div className="py-12 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Why Mullbry?
          </h2>
          {/* <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam
            voluptatum cupiditate veritatis in accusamus quisquam.
          </p> */}
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            <div className="flex">
              <div className="flex-shrink-0">
                <Workers />
              </div>
              <div className="ml-4">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Personal
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  We are not a bank or massive corporation. Just a hardworking
                  team looking to provide you with the best possible rates and
                  service.
                </dd>
              </div>
            </div>

            <div className="flex">
              <div className="flex-shrink-0">
                <PhoneApp />
              </div>
              <div className="ml-4">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Simple
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  It's completely online and we have designed our entire process
                  to make your life easy.
                </dd>
              </div>
            </div>

            <div className="flex">
              <div className="flex-shrink-0">
                <TransparentPage />
              </div>
              <div className="ml-4">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Transparent
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  No hidden fees, double meanings, or confusing financial terms.
                </dd>
              </div>
            </div>

            <div className="flex">
              <div className="flex-shrink-0">
                <Invested />
              </div>
              <div className="ml-4">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Invested
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  We are your partners. We only succeed if you succeed, so we're
                  offering you complimentary financial advice and other
                  services.
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};
