import { HeaderFooterWrapper } from 'components/common/header-footer-wrapper/header-footer-wrapper';

import { AlwaysExpanding } from './sections/always-expanding/always-expanding';
import { HomeStartsHere } from './sections/home-starts-here/home-starts-here';
import { HowItWorks } from './sections/how-it-works/how-it-works';
import { OurRates } from './sections/our-rates/our-rates';
import { WhyMullbry } from './sections/why-mullbry/why-mullbry';

export const LandingPage = (): JSX.Element => {
  return (
    <>
      <HeaderFooterWrapper>
        <HomeStartsHere />
        <WhyMullbry />
        <OurRates />
        <HowItWorks />
        <AlwaysExpanding />
      </HeaderFooterWrapper>
    </>
  );
};
