export const PhoneApp = (): JSX.Element => {
  return (
    <svg
      width="157"
      height="133"
      viewBox="0 0 157 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M120.943 128.523L118.718 129.257L114.823 121.039L118.107 119.955L120.943 128.523Z"
        fill="#FFB8B8"
      />
      <path
        d="M116.919 129.147L121.209 127.731L122.102 130.428L115.111 132.735C114.993 132.381 114.947 132.007 114.974 131.635C115.002 131.263 115.102 130.9 115.27 130.567C115.438 130.234 115.67 129.937 115.953 129.693C116.236 129.45 116.564 129.264 116.919 129.147Z"
        fill="#2F2E41"
      />
      <path
        d="M106.267 130.563L103.924 130.563L102.809 121.539L106.267 121.54L106.267 130.563Z"
        fill="#FFB8B8"
      />
      <path
        d="M102.25 129.895H106.769V132.735H99.4052C99.4052 132.362 99.4788 131.993 99.6218 131.648C99.7648 131.304 99.9743 130.99 100.239 130.727C100.503 130.463 100.816 130.254 101.161 130.111C101.507 129.968 101.877 129.895 102.25 129.895Z"
        fill="#2F2E41"
      />
      <path
        d="M104.101 97.067L103.982 97.5949C102.367 104.733 101.792 115.12 102.275 122.422L102.647 128.063C102.66 128.256 102.746 128.437 102.887 128.57C103.029 128.702 103.216 128.776 103.41 128.776H106.219C106.406 128.776 106.587 128.707 106.727 128.583C106.867 128.459 106.956 128.288 106.978 128.102L109.158 109.599C109.178 109.426 109.258 109.265 109.383 109.143C109.508 109.021 109.671 108.945 109.845 108.929C110.019 108.912 110.194 108.956 110.34 109.052C110.485 109.149 110.594 109.292 110.646 109.459L116.296 126.179C116.343 126.325 116.433 126.455 116.554 126.55C116.675 126.645 116.822 126.701 116.976 126.711L121.267 126.987C121.391 126.995 121.515 126.973 121.628 126.922C121.741 126.872 121.84 126.794 121.917 126.697C121.994 126.599 122.046 126.485 122.068 126.363C122.09 126.241 122.083 126.115 122.045 125.997L119.463 118.907C117.467 112.543 116.394 102.874 116.277 96.2074C116.275 96.1022 116.252 95.9985 116.208 95.9029C116.164 95.8072 116.101 95.7217 116.022 95.6516C115.944 95.5815 115.851 95.5284 115.751 95.4956C115.651 95.4628 115.545 95.451 115.441 95.461L104.774 96.4753C104.614 96.4905 104.462 96.5559 104.342 96.6622C104.221 96.7684 104.137 96.9101 104.101 97.067Z"
        fill="#2F2E41"
      />
      <path
        d="M110.347 67.4269C112.939 67.4269 115.04 65.3286 115.04 62.7402C115.04 60.1518 112.939 58.0535 110.347 58.0535C107.754 58.0535 105.653 60.1518 105.653 62.7402C105.653 65.3286 107.754 67.4269 110.347 67.4269Z"
        fill="#A0616A"
      />
      <path
        d="M107.266 98.6026C106.331 98.6007 105.397 98.5515 104.467 98.4552C104.168 98.4228 103.894 98.2749 103.703 98.0433C103.512 97.8117 103.419 97.5147 103.445 97.2158L105.045 81.7775C105.431 77.4106 106.027 73.1953 109.044 69.6253C109.146 69.5043 109.273 69.4057 109.415 69.3359C109.558 69.266 109.713 69.2264 109.872 69.2194L109.985 69.2151C111.175 69.1656 111.724 69.2088 111.878 69.3631L112.708 70.1797C117.769 73.917 117.979 97.5929 116.732 97.1263C116.491 97.0365 113.611 97.1267 113.41 97.2633C111.784 98.3699 109.171 98.6024 107.266 98.6026Z"
        fill="#005CFF"
      />
      <path
        d="M88.8493 90.0333C88.9688 89.7794 89.1425 89.5547 89.3584 89.3752C89.5742 89.1956 89.8269 89.0655 90.0985 88.994C90.3702 88.9224 90.6543 88.9112 90.9307 88.9612C91.2072 89.0111 91.4693 89.121 91.6986 89.283L97.253 85.3157L97.3877 88.8566L92.0926 92.0812C91.7944 92.4478 91.3719 92.6925 90.9051 92.7689C90.4383 92.8453 89.9597 92.748 89.5599 92.4956C89.1601 92.2432 88.867 91.8531 88.736 91.3993C88.605 90.9454 88.6453 90.4594 88.8493 90.0333Z"
        fill="#A0616A"
      />
      <path
        d="M94.3656 91.08L92.4843 87.7733L109.62 75.4361C110.007 75.1577 110.447 74.9619 110.913 74.861C111.379 74.76 111.861 74.7561 112.328 74.8494C112.796 74.9428 113.239 75.1314 113.63 75.4035C114.021 75.6756 114.352 76.0253 114.602 76.4308C114.852 76.8363 115.015 77.2889 115.081 77.7602C115.148 78.2315 115.117 78.7116 114.989 79.1702C114.862 79.6289 114.641 80.0564 114.34 80.4259C114.04 80.7955 113.666 81.0993 113.243 81.3182L94.3656 91.08Z"
        fill="#005CFF"
      />
      <path
        d="M112.456 66.7029C112.615 66.8594 112.825 66.9534 113.048 66.9676C113.271 66.9819 113.491 66.9154 113.669 66.7804C114.775 65.9617 115.746 64.9425 116.321 63.6994C116.979 62.2749 117.053 60.5269 116.259 59.1731C115.588 58.0286 114.402 57.2901 113.221 56.6837C112.762 56.4252 112.268 56.2351 111.754 56.1193C111.235 56.0049 110.692 56.0925 110.235 56.3641C110.011 56.5041 109.835 56.708 109.729 56.9494C109.624 57.1908 109.593 57.4583 109.642 57.7172C109.075 57.0708 108.348 56.5836 107.534 56.3041C107.021 56.1038 106.46 56.0632 105.924 56.1875C105.174 56.3998 104.627 57.0649 104.328 57.7832C104.085 58.3296 104.002 58.9329 104.086 59.5244C104.131 59.8187 104.236 60.1005 104.397 60.3514C104.557 60.6024 104.768 60.8169 105.017 60.9812C105.637 61.3641 106.428 61.3365 107.139 61.1801C107.851 61.0236 108.54 60.7516 109.266 60.6828C109.991 60.614 110.799 60.7946 111.241 61.3736C111.552 61.7813 111.632 62.314 111.701 62.8217L112.065 65.4624C112.094 65.8444 112.192 66.2181 112.353 66.5658C112.381 66.6159 112.416 66.662 112.456 66.7029Z"
        fill="#2F2E41"
      />
      <path
        d="M122.583 99.5407C122.355 99.3764 122.167 99.1643 122.03 98.9192C121.893 98.6742 121.812 98.4022 121.792 98.1224C121.772 97.8426 121.813 97.5618 121.914 97.2998C122.014 97.0378 122.171 96.8009 122.373 96.6059L119.497 90.421L123.007 90.9448L125.2 96.7378C125.505 97.0983 125.668 97.5582 125.656 98.0303C125.645 98.5025 125.461 98.9541 125.138 99.2996C124.816 99.6451 124.378 99.8604 123.907 99.9049C123.436 99.9493 122.965 99.8197 122.583 99.5407V99.5407Z"
        fill="#A0616A"
      />
      <path
        d="M124.506 92.9705L121.194 94.849L108.838 77.7389C108.56 77.3528 108.364 76.9134 108.262 76.4482C108.161 75.983 108.157 75.502 108.251 75.0352C108.344 74.5685 108.533 74.1259 108.806 73.7354C109.078 73.3448 109.429 73.0145 109.835 72.7651C110.241 72.5158 110.694 72.3528 111.166 72.2862C111.638 72.2197 112.119 72.2511 112.578 72.3784C113.038 72.5058 113.466 72.7263 113.836 73.0263C114.206 73.3264 114.51 73.6994 114.729 74.1221L124.506 92.9705Z"
        fill="#005CFF"
      />
      <path
        d="M94.3422 31.539H93.6119V11.5624C93.6119 8.49584 92.3918 5.5549 90.2202 3.38654C88.0485 1.21818 85.1031 1.51824e-06 82.0319 0H39.6427C36.5715 0 33.6261 1.21817 31.4544 3.38654C29.2827 5.5549 28.0627 8.49584 28.0627 11.5624V121.157C28.0627 124.224 29.2827 127.165 31.4544 129.333C33.6261 131.502 36.5715 132.72 39.6427 132.72H82.0319C85.103 132.72 88.0484 131.502 90.22 129.333C92.3917 127.165 93.6117 124.224 93.6117 121.157V45.7587H94.342L94.3422 31.539Z"
        fill="#E6E6E6"
      />
      <path
        d="M82.4989 3.00684H76.9662C77.2206 3.62976 77.3178 4.30562 77.249 4.97485C77.1802 5.64409 76.9477 6.28617 76.5718 6.84452C76.1959 7.40287 75.6883 7.86035 75.0937 8.17666C74.499 8.49297 73.8356 8.6584 73.1618 8.65838H48.8775C48.2038 8.65834 47.5404 8.49287 46.9458 8.17654C46.3512 7.8602 45.8436 7.40271 45.4678 6.84437C45.092 6.28603 44.8594 5.64397 44.7907 4.97477C44.7219 4.30556 44.8191 3.62973 45.0735 3.00684H39.9056C37.612 3.00684 35.4124 3.91656 33.7906 5.53587C32.1689 7.15518 31.2578 9.35144 31.2578 11.6415V121.077C31.2578 123.367 32.1689 125.564 33.7906 127.183C35.4124 128.802 37.612 129.712 39.9056 129.712H82.4989C84.7924 129.712 86.9921 128.802 88.6138 127.183C90.2356 125.564 91.1467 123.367 91.1467 121.077V11.6413C91.1467 9.3513 90.2356 7.15508 88.6138 5.53581C86.992 3.91653 84.7924 3.00683 82.4989 3.00684H82.4989Z"
        fill="white"
      />
      <path
        d="M61.2022 67.7404C57.2334 67.7404 53.3538 66.5653 50.0539 64.3638C46.754 62.1622 44.182 59.033 42.6632 55.3719C41.1444 51.7109 40.747 47.6823 41.5213 43.7957C42.2956 39.9091 44.2067 36.3391 47.0131 33.537C49.8194 30.735 53.3949 28.8267 57.2874 28.0536C61.1799 27.2805 65.2146 27.6773 68.8813 29.1938C72.548 30.7103 75.6819 33.2783 77.8869 36.5732C80.0918 39.8681 81.2687 43.7418 81.2687 47.7045C81.2626 53.0165 79.1466 58.1093 75.3847 61.8654C71.6228 65.6216 66.5223 67.7344 61.2022 67.7404ZM61.2022 28.0503C57.309 28.0503 53.5033 29.203 50.2662 31.3626C47.0292 33.5223 44.5062 36.5918 43.0163 40.1832C41.5265 43.7745 41.1367 47.7263 41.8962 51.5389C42.6557 55.3514 44.5304 58.8535 47.2833 61.6022C50.0362 64.3509 53.5436 66.2228 57.362 66.9811C61.1804 67.7395 65.1382 67.3503 68.735 65.8627C72.3319 64.3751 75.4061 61.856 77.5691 58.6239C79.732 55.3917 80.8865 51.5918 80.8865 47.7045C80.8806 42.4937 78.8048 37.498 75.1146 33.8134C71.4243 30.1288 66.421 28.0562 61.2022 28.0503Z"
        fill="#3F3D56"
      />
      <path
        d="M68.5724 41.8841C70.291 40.4341 71.3349 38.1436 71.0611 35.9138C70.7874 33.684 69.0918 31.6341 66.8924 31.1606C64.693 30.687 62.1858 31.9978 61.5178 34.143C61.1501 30.0071 60.7265 25.698 58.5242 22.1759C56.5302 18.9867 53.0764 16.7047 49.3332 16.2965C45.5901 15.8882 41.649 17.4301 39.3146 20.38C36.9801 23.3299 36.4085 27.6185 38.0435 31.0052C39.248 33.5001 41.467 35.3569 43.792 36.8674C51.2094 41.6866 60.4107 43.3148 69.1659 42.0283L68.5724 41.8841Z"
        fill="#E6E6E6"
      />
      <path
        d="M43.0161 17.5157C46.1046 20.0275 48.9381 22.8368 51.4754 25.9029C55.4463 30.685 58.6595 36.0465 61.0033 41.8008C61.1748 42.2205 60.4921 42.4033 60.3225 41.9882C56.4877 32.6363 50.3659 24.3937 42.5169 18.0142C42.1644 17.7276 42.6665 17.2315 43.0161 17.5157Z"
        fill="white"
      />
      <path
        d="M44.067 87.3948C45.228 87.3948 46.1692 86.4551 46.1692 85.2958C46.1692 84.1366 45.228 83.1968 44.067 83.1968C42.906 83.1968 41.9648 84.1366 41.9648 85.2958C41.9648 86.4551 42.906 87.3948 44.067 87.3948Z"
        fill="#3F3D56"
      />
      <path
        d="M78.9109 86.8223H52.1557C51.7502 86.8223 51.3613 86.6615 51.0746 86.3752C50.7879 86.0889 50.6268 85.7006 50.6268 85.2957C50.6268 84.8909 50.7879 84.5026 51.0746 84.2163C51.3613 83.93 51.7502 83.7692 52.1557 83.7692H78.9109C79.3164 83.7692 79.7053 83.93 79.992 84.2163C80.2787 84.5026 80.4398 84.8909 80.4398 85.2957C80.4398 85.7006 80.2787 86.0889 79.992 86.3752C79.7053 86.6615 79.3164 86.8223 78.9109 86.8223Z"
        fill="#CCCCCC"
      />
      <path
        d="M70.6127 86.8406H52.0451C51.6347 86.8406 51.2411 86.6779 50.9509 86.3881C50.6608 86.0984 50.4977 85.7054 50.4977 85.2957C50.4977 84.8859 50.6608 84.493 50.9509 84.2032C51.2411 83.9135 51.6347 83.7507 52.0451 83.7507H70.6127C71.0231 83.7507 71.4167 83.9135 71.7069 84.2032C71.997 84.493 72.1601 84.8859 72.1601 85.2957C72.1601 85.7054 71.997 86.0984 71.7069 86.3881C71.4167 86.6779 71.0231 86.8406 70.6127 86.8406Z"
        fill="#005CFF"
      />
      <path
        d="M44.067 96.5538C45.228 96.5538 46.1692 95.614 46.1692 94.4548C46.1692 93.2955 45.228 92.3558 44.067 92.3558C42.906 92.3558 41.9648 93.2955 41.9648 94.4548C41.9648 95.614 42.906 96.5538 44.067 96.5538Z"
        fill="#3F3D56"
      />
      <path
        d="M78.9109 95.9812H52.1557C51.7502 95.9812 51.3613 95.8204 51.0746 95.5341C50.7879 95.2478 50.6268 94.8595 50.6268 94.4547C50.6268 94.0498 50.7879 93.6615 51.0746 93.3752C51.3613 93.089 51.7502 92.9281 52.1557 92.9281H78.9109C79.3164 92.9281 79.7053 93.089 79.992 93.3752C80.2787 93.6615 80.4398 94.0498 80.4398 94.4547C80.4398 94.8595 80.2787 95.2478 79.992 95.5341C79.7053 95.8204 79.3164 95.9812 78.9109 95.9812Z"
        fill="#CCCCCC"
      />
      <path
        d="M44.067 105.713C45.228 105.713 46.1692 104.774 46.1692 103.614C46.1692 102.455 45.228 101.515 44.067 101.515C42.906 101.515 41.9648 102.455 41.9648 103.614C41.9648 104.774 42.906 105.713 44.067 105.713Z"
        fill="#3F3D56"
      />
      <path
        d="M78.9109 105.141H52.1557C51.7502 105.141 51.3613 104.98 51.0746 104.694C50.7879 104.407 50.6268 104.019 50.6268 103.614C50.6268 103.209 50.7879 102.821 51.0746 102.535C51.3613 102.248 51.7502 102.088 52.1557 102.088H78.9109C79.3164 102.088 79.7053 102.248 79.992 102.535C80.2787 102.821 80.4398 103.209 80.4398 103.614C80.4398 104.019 80.2787 104.407 79.992 104.694C79.7053 104.98 79.3164 105.141 78.9109 105.141Z"
        fill="#CCCCCC"
      />
      <path
        d="M64.4973 95.9998H52.0451C51.6347 95.9998 51.2411 95.8371 50.9509 95.5473C50.6608 95.2576 50.4977 94.8646 50.4977 94.4549C50.4977 94.0452 50.6608 93.6522 50.9509 93.3625C51.2411 93.0727 51.6347 92.91 52.0451 92.91H64.4973C64.9076 92.91 65.3012 93.0727 65.5914 93.3625C65.8815 93.6522 66.0446 94.0452 66.0446 94.4549C66.0446 94.8646 65.8815 95.2576 65.5914 95.5473C65.3012 95.8371 64.9076 95.9998 64.4973 95.9998Z"
        fill="#005CFF"
      />
      <path
        d="M75.5816 105.159H52.0451C51.6347 105.159 51.2411 104.996 50.9509 104.707C50.6608 104.417 50.4977 104.024 50.4977 103.614C50.4977 103.204 50.6608 102.811 50.9509 102.522C51.2411 102.232 51.6347 102.069 52.0451 102.069H75.5816C75.992 102.069 76.3855 102.232 76.6757 102.522C76.9659 102.811 77.1289 103.204 77.1289 103.614C77.1289 104.024 76.9659 104.417 76.6757 104.707C76.3855 104.996 75.992 105.159 75.5816 105.159Z"
        fill="#005CFF"
      />
      <path
        d="M71.8267 44.5892H69.2V36.8393C69.2003 36.6116 69.291 36.3934 69.4522 36.2324C69.6134 36.0714 69.832 35.9808 70.06 35.9806H70.9667C71.1947 35.9808 71.4133 36.0714 71.5745 36.2324C71.7357 36.3934 71.8264 36.6116 71.8267 36.8393V44.5892Z"
        fill="#3F3D56"
      />
      <path
        d="M73.4189 57.777H48.3111L48.221 43.6476C48.22 43.4293 48.263 43.2131 48.3473 43.0117C48.4316 42.8103 48.5556 42.6279 48.7119 42.4753L55.0192 36.3413C55.3184 36.0493 55.7194 35.8844 56.1378 35.8813L66.6942 35.7849C66.9336 35.7835 67.1703 35.8345 67.3878 35.9344C67.6052 36.0343 67.798 36.1807 67.9526 36.3632L67.9535 36.3643L72.9675 42.5039C73.2591 42.8617 73.4185 43.3088 73.4189 43.77L73.4189 57.777Z"
        fill="#3F3D56"
      />
      <path
        d="M73.4192 57.7769H59.9264V45.1141C59.9265 44.7282 60.0642 44.3551 60.3149 44.0615L66.1773 37.1988V37.1989C66.3297 37.0202 66.5191 36.8768 66.7325 36.7784C66.9459 36.6801 67.1781 36.6293 67.4132 36.6295H67.4193C67.6554 36.6302 67.8885 36.6823 68.1022 36.7823C68.316 36.8822 68.5054 37.0276 68.6571 37.2082L73.0386 42.4163C73.2842 42.7086 73.419 43.078 73.4192 43.4596V57.7769Z"
        fill="#005CFF"
      />
      <path
        d="M67.6958 52.9135H65.7436C65.617 52.9134 65.4955 52.8631 65.406 52.7737C65.3164 52.6842 65.266 52.563 65.2659 52.4365V50.8022C65.266 50.6758 65.3164 50.5545 65.406 50.4651C65.4955 50.3757 65.617 50.3253 65.7436 50.3252H67.6958C67.8225 50.3253 67.9439 50.3757 68.0335 50.4651C68.123 50.5545 68.1734 50.6758 68.1736 50.8022V52.4365C68.1734 52.563 68.123 52.6842 68.0335 52.7737C67.9439 52.8631 67.8225 52.9134 67.6958 52.9135Z"
        fill="white"
      />
      <path
        d="M67.6958 48.7256H65.7436C65.617 48.7254 65.4955 48.6751 65.406 48.5857C65.3164 48.4963 65.266 48.375 65.2659 48.2485V46.6523C65.266 46.5258 65.3164 46.4046 65.406 46.3151C65.4955 46.2257 65.617 46.1754 65.7436 46.1753H67.6958C67.8225 46.1754 67.9439 46.2257 68.0335 46.3151C68.123 46.4046 68.1734 46.5258 68.1736 46.6523V48.2485C68.1734 48.375 68.123 48.4963 68.0335 48.5857C67.9439 48.6751 67.8225 48.7254 67.6958 48.7256Z"
        fill="white"
      />
      <path
        d="M55.623 52.2772H53.6709C53.5442 52.2771 53.4228 52.2268 53.3332 52.1373C53.2436 52.0479 53.1932 51.9266 53.1931 51.8002V50.166C53.1932 50.0395 53.2436 49.9183 53.3332 49.8289C53.4228 49.7394 53.5442 49.6891 53.6709 49.689H55.623C55.7496 49.6891 55.8711 49.7394 55.9606 49.8289C56.0502 49.9183 56.1006 50.0395 56.1007 50.166V51.8002C56.1006 51.9266 56.0502 52.0479 55.9606 52.1373C55.8711 52.2268 55.7496 52.2771 55.623 52.2772Z"
        fill="white"
      />
      <path
        d="M55.623 48.0896H53.6709C53.5442 48.0894 53.4228 48.0391 53.3332 47.9497C53.2436 47.8603 53.1932 47.739 53.1931 47.6125V46.0164C53.1932 45.8899 53.2436 45.7687 53.3332 45.6792C53.4228 45.5898 53.5442 45.5395 53.6709 45.5393H55.623C55.7496 45.5395 55.8711 45.5898 55.9606 45.6792C56.0502 45.7687 56.1006 45.8899 56.1007 46.0164V47.6125C56.1006 47.739 56.0502 47.8603 55.9606 47.9497C55.8711 48.0391 55.7496 48.0894 55.623 48.0896Z"
        fill="white"
      />
      <path
        d="M78.2784 57.9423H44.1265C44.0758 57.9423 44.0272 57.9222 43.9913 57.8864C43.9555 57.8506 43.9354 57.8021 43.9354 57.7515C43.9354 57.7008 43.9555 57.6523 43.9913 57.6165C44.0272 57.5807 44.0758 57.5606 44.1265 57.5606H78.2784C78.329 57.5606 78.3777 57.5807 78.4135 57.6165C78.4493 57.6523 78.4695 57.7008 78.4695 57.7515C78.4695 57.8021 78.4493 57.8506 78.4135 57.8864C78.3777 57.9222 78.329 57.9423 78.2784 57.9423Z"
        fill="#3F3D56"
      />
      <path
        d="M156.461 133H0.325142C0.274457 133 0.225848 132.98 0.190008 132.944C0.154168 132.908 0.134033 132.86 0.134033 132.809C0.134033 132.759 0.154168 132.71 0.190008 132.674C0.225848 132.638 0.274457 132.618 0.325142 132.618H156.461C156.512 132.618 156.561 132.638 156.596 132.674C156.632 132.71 156.652 132.759 156.652 132.809C156.652 132.86 156.632 132.908 156.596 132.944C156.561 132.98 156.512 133 156.461 133Z"
        fill="#3F3D56"
      />
    </svg>
  );
};
