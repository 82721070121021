import { Link } from 'react-router-dom';

import { MullbryLogo, VerticalDivider } from 'components/common/svg';
import { PortalRoutes } from 'routes/portal.enums';
import { AppRoutes } from 'routes/routes.enums';

import { AlertBanner } from './alert-banner/alert-banner';

export const SiteHeader = (): JSX.Element => {
  return (
    <div className="sticky top-0 bg-gray-100">
      <AlertBanner />
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex flex-row flex-nowrap justify-between items-center py-6 md:justify-start space-x-8">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to={AppRoutes.Home}>
              <MullbryLogo />
            </Link>
          </div>

          <div className="flex flex-row items-center justify-end md:flex-1 lg:w-0 ml-10">
            <nav className="md:flex md:space-x-10 mr-8">
              <div className="relative">
                {/* <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" --> */}
                <Link
                  type="button"
                  className="group rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  to={AppRoutes.About}
                >
                  <span>About</span>
                </Link>
              </div>
              <div className="relative">
                {/* <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" --> */}
                <Link
                  type="button"
                  className="group rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  to={AppRoutes.Contact}
                >
                  <span>Contact</span>
                </Link>
              </div>
            </nav>
            <VerticalDivider className="mr-8 hidden lg:block" />
            <div className="flex flex-col p-r-2 space-y-4 md:flex-row md:space-y-0 lg:space-y-0">
              <a
                type="button"
                className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700"
                href={PortalRoutes.ApplyNowPage}
              >
                <span>Apply Now</span>
              </a>
              <a
                type="button"
                className="md:ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-gray-400 rounded-md shadow-sm text-base font-medium text-gray-500 hover:text-gray-900"
                href={PortalRoutes.SignInBorrowerPage}
              >
                <span>Sign In</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
