import { Link } from 'react-router-dom';

import { EmailInputSurveyTrigger } from 'components/common/email-input-survey-trigger/email-input-survery-trigger';
import { MullbryLogo } from 'components/common/svg';
import { AppRoutes } from 'routes/routes.enums';

import { LegalFooter } from './legal-footer/legal-footer';

export const SiteFooter = (): JSX.Element => {
  return (
    <footer className="bg-gray-100" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 xl:grid xl:grid-cols-2 xl:gap-8">
          <div className="grid grid-cols-3 md:mb-8 lg:mb-0">
            <Link to={AppRoutes.Home}>
              <MullbryLogo />
            </Link>
            <div className="grid col-span-2 grid-cols-2 mb-8 sm:ml-0 sm:mb-0 md:gap-8">
              <div className="inline pl-8 sm:pl-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Home
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link
                      type="button"
                      className="text-base text-gray-500 hover:text-gray-900"
                      to={AppRoutes.About}
                    >
                      <span>About</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      type="button"
                      className="text-base text-gray-500 hover:text-gray-900"
                      to={AppRoutes.Contact}
                    >
                      <span>Contact</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      type="button"
                      className="text-base text-gray-500 hover:text-gray-900"
                      to={AppRoutes.Documents}
                    >
                      <span>Policies</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      type="button"
                      className="text-base text-gray-500 hover:text-gray-900"
                      to={AppRoutes.TermsOfUse}
                    >
                      <span>Terms of Use</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="inline sm:mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Loan Process
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link
                      type="button"
                      className="text-base text-gray-500 hover:text-gray-900"
                      to={AppRoutes.HowItWorks}
                    >
                      <span>Application</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="md:mt-0 sm:mt-8">
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Want us to keep you in the loop?
            </h3>
            <p className="mt-4 text-base text-gray-500">
              Enter your email below to get updates from Mullbry.
            </p>
            <EmailInputSurveyTrigger />
          </div>
        </div>
        <LegalFooter />
      </div>
    </footer>
  );
};
