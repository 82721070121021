export const SoldHome = (): JSX.Element => {
  return (
    <svg
      width="507"
      height="433"
      viewBox="0 0 507 433"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M405.715 138.597V100.631H439.477V170.999L405.715 138.597Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M409.721 141.227V108.064H434.899V170.999L409.721 141.227Z"
        fill="#005CFF"
      />
      <path
        d="M466.944 62.8945C484.326 62.8945 498.417 48.8151 498.417 31.4472C498.417 14.0794 484.326 0 466.944 0C449.562 0 435.471 14.0794 435.471 31.4472C435.471 48.8151 449.562 62.8945 466.944 62.8945Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M466.944 56.605C480.85 56.605 492.123 45.3415 492.123 31.4472C492.123 17.553 480.85 6.28943 466.944 6.28943C453.039 6.28943 441.766 17.553 441.766 31.4472C441.766 45.3415 453.039 56.605 466.944 56.605Z"
        fill="#FDB851"
      />
      <path
        d="M197.702 174.658C202.348 169.348 205.799 163.103 207.822 156.346C209.845 149.588 210.392 142.476 209.427 135.489C208.462 128.502 206.007 121.803 202.228 115.846C198.449 109.888 193.434 104.811 187.522 100.957C190.578 96.2293 192.2 90.7188 192.191 85.0905C192.191 69.1725 179.562 56.2677 163.986 56.2677C151.157 56.2677 140.341 65.0329 136.925 77.0172C129.56 64.5812 116.199 56.2677 100.937 56.2677C77.7216 56.2677 58.9065 75.4963 58.9065 99.2189C58.9012 104.272 59.7724 109.289 61.4816 114.045C60.629 113.976 59.7649 113.913 58.9065 113.913C40.2746 113.913 25.1447 129.351 25.1447 148.391C25.1447 149.563 25.2019 150.724 25.3164 151.873C17.7368 155.274 11.3093 160.802 6.81504 167.784C2.3208 174.767 -0.0465758 182.903 0.000694306 191.205C0.000694306 214.848 18.7586 234.019 41.894 234.019C44.3253 234.016 46.7515 233.798 49.1442 233.367C49.0183 234.791 48.9439 236.226 48.9439 237.695C48.9439 264.225 69.9907 285.724 95.953 285.724C105.552 285.722 114.911 282.723 122.722 277.147C129.007 282.665 137.085 285.713 145.451 285.724C164.844 285.724 180.569 269.657 180.569 249.834C180.569 248.976 180.501 248.119 180.443 247.29H180.569C201.341 247.29 218.177 230.085 218.177 208.861C218.209 201.837 216.318 194.937 212.71 188.909C209.101 182.88 203.911 177.952 197.702 174.658Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M192.272 174.429C196.634 169.445 199.874 163.583 201.773 157.24C203.673 150.896 204.187 144.219 203.281 137.66C202.375 131.101 200.07 124.813 196.522 119.221C192.974 113.629 188.266 108.863 182.715 105.245C185.58 100.816 187.102 95.6534 187.099 90.3794C187.099 75.4391 175.248 63.3234 160.621 63.3234C148.604 63.3234 138.43 71.5511 135.22 82.7978C131.777 76.8953 126.85 71.9935 120.929 68.5778C115.007 65.1621 108.295 63.3509 101.458 63.3234C79.6672 63.3234 62.0023 81.3741 62.0023 103.639C61.9965 108.382 62.8136 113.091 64.4171 117.556C63.616 117.493 62.8149 117.43 62.0023 117.43C44.4919 117.424 30.3291 131.907 30.3291 149.803C30.3291 150.907 30.3863 151.993 30.4893 153.068C23.3711 156.257 17.3336 161.445 13.1115 167.999C8.88933 174.553 6.66438 182.193 6.70727 189.987C6.70727 212.183 24.3149 230.171 46.0312 230.171C48.3128 230.168 50.5897 229.963 52.8351 229.559C52.7207 230.897 52.6463 232.252 52.6463 233.619C52.6463 258.519 72.3998 278.708 96.7713 278.708C105.777 278.72 114.561 275.921 121.898 270.704C127.804 275.866 135.384 278.711 143.231 278.708C161.434 278.708 176.197 263.625 176.197 245.02C176.197 244.214 176.135 243.425 176.077 242.63H176.197C195.694 242.63 211.499 226.477 211.499 206.557C211.533 199.959 209.759 193.477 206.37 187.814C202.981 182.15 198.105 177.522 192.272 174.429Z"
        fill="#00897B"
      />
      <path
        d="M122.659 122.97H112.411V377.699H122.659V122.97Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M120.479 126.549H115.323V377.699H120.479V126.549Z"
        fill="#795548"
      />
      <path
        d="M157.16 154.91L114.855 200.558L118.638 204.058L160.943 158.41L157.16 154.91Z"
        fill="#795548"
      />
      <path
        d="M74.4184 206.94L71.3444 211.076L115.208 243.625L118.282 239.49L74.4184 206.94Z"
        fill="#795548"
      />
      <path
        d="M133.331 202.978C126.411 195.619 122.552 185.905 122.539 175.807C122.539 161.262 130.453 148.511 142.344 141.324C139.669 137.423 138.236 132.805 138.235 128.076C138.235 114.76 149.417 103.965 163.214 103.965C174.578 103.965 184.152 111.295 187.179 121.323C190.585 115.97 195.295 111.569 200.869 108.533C206.443 105.496 212.698 103.925 219.046 103.965C239.607 103.965 256.242 120.054 256.242 139.9C256.241 144.139 255.466 148.342 253.953 152.302C254.708 152.245 255.463 152.193 256.242 152.193C272.745 152.193 286.118 165.104 286.118 181.033C286.117 182.005 286.065 182.977 285.964 183.944C299.159 189.433 308.401 202.097 308.401 216.849C308.401 236.632 291.806 252.664 271.297 252.664C269.145 252.653 266.997 252.462 264.877 252.093C264.985 253.288 265.054 254.488 265.054 255.712C265.054 277.908 246.416 295.901 223.424 295.901C214.997 295.917 206.755 293.425 199.751 288.743C194.076 293.392 186.958 295.923 179.62 295.901C162.452 295.901 148.519 282.459 148.519 265.878C148.519 265.158 148.576 264.454 148.627 263.745H148.519C130.127 263.745 115.214 249.348 115.214 231.595C115.214 219.119 122.591 208.307 133.331 202.978Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M136.713 202.846C130.099 195.798 126.416 186.499 126.413 176.836C126.413 162.914 133.99 150.712 145.371 143.834C142.797 140.097 141.418 135.666 141.417 131.129C141.417 118.385 152.124 108.047 165.325 108.047C176.198 108.047 185.353 115.063 188.266 124.663C191.525 119.546 196.03 115.34 201.36 112.437C206.689 109.534 212.668 108.03 218.737 108.064C238.417 108.064 254.365 123.462 254.365 142.456C254.369 146.514 253.63 150.539 252.185 154.332C252.906 154.28 253.627 154.223 254.365 154.223C270.158 154.223 282.977 166.585 282.977 181.828C282.977 182.758 282.929 183.687 282.833 184.612C295.463 189.867 304.309 201.988 304.309 216.105C304.309 235.037 288.413 250.412 268.797 250.412C266.738 250.4 264.684 250.216 262.656 249.863C262.759 251.006 262.822 252.15 262.822 253.328C262.822 274.574 244.98 291.796 222.978 291.796C214.9 291.814 207.001 289.425 200.289 284.935C194.858 289.388 188.046 291.814 181.021 291.796C164.581 291.796 151.265 278.931 151.265 263.059C151.265 262.373 151.322 261.698 151.374 261.018H151.265C133.658 261.018 119.386 247.238 119.386 230.245C119.357 218.295 126.413 207.946 136.713 202.846Z"
        fill="#009688"
      />
      <path
        d="M209.896 162.611H198.24V376.406H209.896V162.611Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M207.482 162.611H201.038V376.406H207.482V162.611Z"
        fill="#795548"
      />
      <path
        d="M169.001 185.448L164.4 189.955L202.608 228.899L207.209 224.392L169.001 185.448Z"
        fill="#795548"
      />
      <path
        d="M242.669 229.698L203.061 257.471L206.763 262.741L246.371 234.967L242.669 229.698Z"
        fill="#795548"
      />
      <path
        d="M465.348 200.119H177.966C171.402 200.119 166.086 203.069 166.086 206.717V417.517H477.245V206.717C477.245 203.069 471.911 200.119 465.348 200.119Z"
        fill="url(#paint6_linear)"
      />
      <path
        d="M320.75 79.4987L170.83 190.765V409.477H471.568V198.701L320.75 79.4987Z"
        fill="white"
      />
      <path
        d="M321.047 213.218C339.485 213.218 354.432 198.284 354.432 179.861C354.432 161.439 339.485 146.504 321.047 146.504C302.61 146.504 287.663 161.439 287.663 179.861C287.663 198.284 302.61 213.218 321.047 213.218Z"
        fill="url(#paint7_linear)"
      />
      <path
        d="M258.456 244.191H199.442V303.157H258.456V244.191Z"
        fill="url(#paint8_linear)"
      />
      <path
        d="M441.463 244.191H382.448V303.157H441.463V244.191Z"
        fill="url(#paint9_linear)"
      />
      <path
        d="M141.915 246.261L133.921 201.583L321.408 57.6057L507.001 204.459L501.851 244.545L321.379 108.476L141.915 246.261Z"
        fill="url(#paint10_linear)"
      />
      <path
        d="M142.281 237.289L136.782 206.42L321.362 62.5056L504.139 206.431L498.606 237.272L321.333 97.6808L142.281 237.289Z"
        fill="#005CFF"
      />
      <path
        d="M356.818 310.899H284.688V409.477H356.818V310.899Z"
        fill="url(#paint11_linear)"
      />
      <path
        d="M354.729 312.986H286.771V409.477H354.729V312.986Z"
        fill="#005CFF"
      />
      <path
        d="M256.968 246.272H202.125V265.632H256.968V246.272Z"
        fill="#005CFF"
      />
      <path
        opacity="0.2"
        d="M256.968 265.632H202.125V301.07H256.968V265.632Z"
        fill="#005CFF"
      />
      <path
        d="M439.38 246.272H384.537V265.632H439.38V246.272Z"
        fill="#005CFF"
      />
      <path
        opacity="0.2"
        d="M439.38 265.632H384.537V301.07H439.38V265.632Z"
        fill="#005CFF"
      />
      <path
        d="M320.75 209.942C324.745 209.942 328.701 209.155 332.391 207.627C336.082 206.098 339.434 203.858 342.258 201.034C345.081 198.21 347.32 194.858 348.847 191.169C350.373 187.48 351.156 183.527 351.153 179.535H290.347C290.343 183.527 291.127 187.48 292.653 191.169C294.179 194.858 296.418 198.21 299.242 201.034C302.065 203.858 305.418 206.098 309.108 207.627C312.799 209.155 316.755 209.942 320.75 209.942Z"
        fill="#005CFF"
      />
      <path
        opacity="0.2"
        d="M320.75 209.645C328.813 209.645 336.546 206.444 342.248 200.747C347.949 195.05 351.153 187.323 351.153 179.266H290.347C290.347 187.323 293.55 195.05 299.252 200.747C304.953 206.444 312.686 209.645 320.75 209.645Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M320.75 149.186C312.691 149.186 304.963 152.382 299.262 158.073C293.561 163.764 290.355 171.483 290.347 179.535H351.153C351.145 171.483 347.938 163.764 342.238 158.073C336.537 152.382 328.808 149.186 320.75 149.186Z"
        fill="#005CFF"
      />
      <path
        opacity="0.2"
        d="M320.75 149.186C312.691 149.186 304.963 152.382 299.262 158.073C293.561 163.764 290.355 171.483 290.347 179.535H351.153C351.145 171.483 347.938 163.764 342.238 158.073C336.537 152.382 328.808 149.186 320.75 149.186Z"
        fill="#005CFF"
      />
      <path
        d="M338.93 368.081C342.88 368.081 346.083 364.882 346.083 360.934C346.083 356.987 342.88 353.787 338.93 353.787C334.979 353.787 331.777 356.987 331.777 360.934C331.777 364.882 334.979 368.081 338.93 368.081Z"
        fill="url(#paint12_linear)"
      />
      <path
        d="M338.93 366.298C341.894 366.298 344.297 363.896 344.297 360.934C344.297 357.972 341.894 355.571 338.93 355.571C335.965 355.571 333.562 357.972 333.562 360.934C333.562 363.896 335.965 366.298 338.93 366.298Z"
        fill="#263238"
      />
      <path
        d="M363.37 395.481H275.744V424.069H363.37V395.481Z"
        fill="url(#paint13_linear)"
      />
      <path
        d="M361.579 397.859H277.529V422.279H361.579V397.859Z"
        fill="white"
      />
      <path
        d="M193.719 329.55C195.86 327.102 197.45 324.224 198.382 321.11C199.313 317.995 199.565 314.717 199.121 311.497C198.676 308.277 197.544 305.19 195.803 302.444C194.062 299.699 191.751 297.358 189.027 295.581C190.436 293.402 191.183 290.862 191.179 288.268C191.258 285.102 190.174 282.016 188.131 279.594C186.089 277.172 183.229 275.582 180.092 275.124C176.955 274.665 173.759 275.371 171.108 277.107C168.457 278.843 166.534 281.49 165.703 284.546C164.011 281.645 161.59 279.236 158.679 277.558C155.768 275.88 152.468 274.991 149.108 274.98C138.407 274.98 129.732 283.843 129.732 294.781C129.726 297.119 130.126 299.441 130.917 301.642C130.522 301.613 130.133 301.579 129.732 301.579C121.149 301.579 114.179 308.692 114.179 317.469C114.179 318.012 114.179 318.543 114.259 319.075C110.763 320.642 107.799 323.189 105.725 326.408C103.652 329.627 102.559 333.378 102.58 337.206C102.58 348.104 111.226 356.938 121.892 356.938C123.013 356.935 124.131 356.834 125.234 356.635C125.177 357.292 125.143 357.955 125.143 358.63C125.143 370.86 134.871 380.775 146.813 380.775C151.237 380.776 155.55 379.396 159.151 376.83C162.049 379.37 165.773 380.772 169.628 380.775C178.567 380.775 185.817 373.342 185.817 364.233C185.817 363.839 185.788 363.45 185.76 363.061H185.817C195.39 363.061 203.15 355.131 203.15 345.337C203.169 342.096 202.3 338.912 200.638 336.129C198.976 333.346 196.583 331.071 193.719 329.55Z"
        fill="url(#paint14_linear)"
      />
      <path
        d="M191.213 329.447C193.223 327.149 194.716 324.447 195.591 321.524C196.465 318.6 196.702 315.523 196.284 312.5C195.867 309.477 194.804 306.579 193.169 304.001C191.534 301.424 189.365 299.227 186.807 297.56C188.129 295.515 188.83 293.132 188.827 290.698C188.902 287.726 187.884 284.829 185.967 282.555C184.049 280.281 181.365 278.788 178.42 278.358C175.476 277.928 172.475 278.59 169.987 280.221C167.498 281.851 165.693 284.335 164.913 287.205C163.326 284.485 161.056 282.226 158.327 280.651C155.598 279.077 152.505 278.242 149.354 278.228C139.311 278.228 131.168 286.547 131.168 296.81C131.167 298.997 131.544 301.168 132.284 303.226C131.918 303.226 131.546 303.169 131.168 303.169C123.106 303.169 116.571 309.847 116.571 318.086C116.571 318.595 116.571 319.098 116.645 319.59C113.364 321.06 110.581 323.451 108.635 326.471C106.689 329.492 105.662 333.013 105.681 336.606C105.681 346.835 113.795 355.131 123.809 355.131C124.861 355.13 125.91 355.036 126.945 354.851C126.894 355.468 126.859 356.091 126.859 356.72C126.859 368.201 135.964 377.504 147.197 377.504C151.349 377.505 155.399 376.21 158.779 373.799C161.5 376.185 164.995 377.502 168.615 377.504C177.01 377.504 183.808 370.551 183.808 361.975C183.808 361.603 183.78 361.237 183.751 360.871H183.808C192.792 360.871 200.083 353.438 200.083 344.244C200.095 341.204 199.276 338.218 197.713 335.61C196.149 333.002 193.902 330.871 191.213 329.447Z"
        fill="#00897B"
      />
      <path
        d="M159.128 305.73H154.401V423.148H159.128V305.73Z"
        fill="url(#paint15_linear)"
      />
      <path
        d="M158.121 307.377H155.746V423.148H158.121V307.377Z"
        fill="#795548"
      />
      <path
        d="M175.037 320.428L155.535 341.471L157.277 343.084L176.779 322.04L175.037 320.428Z"
        fill="#795548"
      />
      <path
        d="M136.889 344.445L135.473 346.35L155.691 361.353L157.107 359.448L136.889 344.445Z"
        fill="#795548"
      />
      <path
        d="M164.055 342.609C160.862 339.218 159.084 334.738 159.082 330.082C159.103 326.868 159.957 323.714 161.562 320.928C163.167 318.142 165.467 315.819 168.238 314.187C166.998 312.387 166.333 310.254 166.332 308.069C166.332 301.928 171.482 296.954 177.846 296.954C180.304 296.925 182.705 297.688 184.695 299.13C186.686 300.572 188.158 302.615 188.895 304.958C190.465 302.49 192.636 300.461 195.206 299.06C197.775 297.66 200.658 296.935 203.585 296.954C213.061 296.954 220.752 304.387 220.752 313.535C220.753 315.489 220.396 317.427 219.699 319.252C220.048 319.252 220.397 319.201 220.752 319.201C228.357 319.201 234.525 325.153 234.525 332.495C234.526 332.943 234.503 333.392 234.457 333.838C237.489 335.061 240.09 337.155 241.93 339.855C243.771 342.555 244.769 345.74 244.797 349.007C244.797 358.127 237.141 365.52 227.693 365.52C226.702 365.519 225.712 365.435 224.735 365.268C224.786 365.84 224.815 366.372 224.815 366.938C224.815 377.167 216.231 385.463 205.622 385.463C201.731 385.474 197.926 384.325 194.692 382.164C192.071 384.3 188.787 385.459 185.405 385.44C177.485 385.44 171.07 379.242 171.07 371.598C171.07 371.266 171.07 370.94 171.122 370.614H171.07C162.59 370.614 155.717 363.976 155.717 355.794C155.737 353.038 156.529 350.342 158.002 348.012C159.476 345.682 161.572 343.811 164.055 342.609Z"
        fill="url(#paint16_linear)"
      />
      <path
        d="M165.606 342.546C162.548 339.295 160.845 335.001 160.845 330.539C160.862 327.465 161.676 324.448 163.207 321.782C164.739 319.115 166.935 316.891 169.583 315.324C168.395 313.602 167.759 311.561 167.757 309.47C167.757 303.592 172.69 298.829 178.778 298.829C181.129 298.805 183.426 299.536 185.329 300.914C187.233 302.293 188.644 304.245 189.353 306.485C190.856 304.124 192.935 302.182 195.394 300.843C197.853 299.504 200.612 298.811 203.413 298.829C212.483 298.829 219.836 305.925 219.836 314.684C219.837 316.554 219.496 318.408 218.829 320.156C219.161 320.156 219.493 320.104 219.836 320.104C227.115 320.104 232.998 325.822 232.998 332.832C232.997 333.262 232.974 333.691 232.929 334.118C235.831 335.288 238.321 337.292 240.083 339.876C241.846 342.46 242.801 345.509 242.829 348.636C242.829 357.361 235.498 364.439 226.457 364.439C225.498 364.44 224.541 364.36 223.596 364.199C223.647 364.725 223.676 365.257 223.676 365.794C223.676 375.589 215.453 383.519 205.307 383.519C201.584 383.53 197.942 382.431 194.847 380.363C192.343 382.413 189.203 383.529 185.966 383.519C178.389 383.519 172.232 377.59 172.232 370.271C172.232 369.957 172.232 369.642 172.283 369.334H172.232C164.118 369.334 157.537 362.981 157.537 355.148C157.567 352.504 158.34 349.921 159.766 347.693C161.192 345.465 163.215 343.682 165.606 342.546Z"
        fill="#009688"
      />
      <path
        d="M199.339 323.998H193.965V422.548H199.339V323.998Z"
        fill="url(#paint17_linear)"
      />
      <path
        d="M198.229 323.998H195.259V422.548H198.229V323.998Z"
        fill="#795548"
      />
      <path
        d="M180.49 334.528L178.369 336.606L195.982 354.559L198.103 352.482L180.49 334.528Z"
        fill="#795548"
      />
      <path
        d="M214.452 354.918L196.194 367.72L197.9 370.149L216.158 357.347L214.452 354.918Z"
        fill="#795548"
      />
      <path
        d="M51.0555 288.034L89.7157 328.183L133.457 288.034"
        stroke="#37474F"
        strokeMiterlimit="10"
      />
      <path
        d="M38.8497 267.702H12.9103V427.797H38.8497V267.702Z"
        fill="url(#paint18_linear)"
      />
      <path
        d="M36.8126 270.749H14.9418V422.205H36.8126V270.749Z"
        fill="#F5F5F5"
      />
      <path
        d="M161.943 274.312H6.29529V302.265H161.943V274.312Z"
        fill="url(#paint19_linear)"
      />
      <path
        d="M8.3324 277.359L8.3324 299.212H159.912V277.359H8.3324Z"
        fill="#F5F5F5"
      />
      <path
        d="M26.1346 292.608C28.6629 292.608 30.7125 290.56 30.7125 288.034C30.7125 285.508 28.6629 283.46 26.1346 283.46C23.6063 283.46 21.5568 285.508 21.5568 288.034C21.5568 290.56 23.6063 292.608 26.1346 292.608Z"
        fill="url(#paint20_linear)"
      />
      <path
        d="M148.719 292.608C151.247 292.608 153.297 290.56 153.297 288.034C153.297 285.508 151.247 283.46 148.719 283.46C146.19 283.46 144.141 285.508 144.141 288.034C144.141 290.56 146.19 292.608 148.719 292.608Z"
        fill="url(#paint21_linear)"
      />
      <path
        d="M26.1347 291.59C28.1005 291.59 29.694 289.998 29.694 288.034C29.694 286.07 28.1005 284.478 26.1347 284.478C24.169 284.478 22.5754 286.07 22.5754 288.034C22.5754 289.998 24.169 291.59 26.1347 291.59Z"
        fill="#E6E6E6"
      />
      <path
        d="M148.719 291.59C150.685 291.59 152.278 289.998 152.278 288.034C152.278 286.07 150.685 284.478 148.719 284.478C146.753 284.478 145.16 286.07 145.16 288.034C145.16 289.998 146.753 291.59 148.719 291.59Z"
        fill="#E6E6E6"
      />
      <path
        d="M141.6 325.639H43.4276V392.216H141.6V325.639Z"
        fill="url(#paint22_linear)"
      />
      <path
        d="M140.833 326.405H44.1887V390.443H140.833V326.405Z"
        fill="#005CFF"
      />
      <path d="M123.54 336.566H55.3816V342.666H123.54V336.566Z" fill="white" />
      <path d="M116.164 348.51H67.8448V354.611H116.164V348.51Z" fill="white" />
      <path
        d="M120.233 360.454H61.7391V366.555H120.233V360.454Z"
        fill="white"
      />
      <path
        d="M124.302 372.398H56.1427V378.499H124.302V372.398Z"
        fill="white"
      />
      <path
        d="M421.017 347.904C419.072 345.678 417.628 343.062 416.782 340.231C415.935 337.4 415.707 334.421 416.112 331.494C416.516 328.568 417.545 325.762 419.127 323.266C420.71 320.771 422.81 318.644 425.286 317.028C424.004 315.047 423.325 312.738 423.329 310.379C423.252 307.497 424.235 304.688 426.093 302.482C427.95 300.276 430.552 298.828 433.407 298.411C436.261 297.994 439.17 298.637 441.581 300.219C443.993 301.8 445.74 304.211 446.493 306.994C448.031 304.357 450.231 302.167 452.877 300.641C455.523 299.116 458.522 298.308 461.577 298.297C471.305 298.297 479.19 306.359 479.19 316.296C479.194 318.415 478.83 320.518 478.114 322.512C478.469 322.512 478.83 322.454 479.19 322.454C487.001 322.454 493.33 328.921 493.33 336.903C493.33 337.395 493.33 337.881 493.256 338.361C496.434 339.785 499.13 342.101 501.016 345.027C502.901 347.954 503.895 351.365 503.876 354.845C503.876 364.754 496.014 372.787 486.32 372.787C485.301 372.785 484.284 372.694 483.282 372.513C483.333 373.084 483.368 373.713 483.368 374.325C483.368 385.446 474.549 394.457 463.665 394.457C459.643 394.459 455.719 393.206 452.444 390.872C449.806 393.183 446.418 394.457 442.91 394.457C434.785 394.457 428.193 387.722 428.193 379.414C428.193 379.054 428.193 378.699 428.244 378.345H428.193C419.489 378.345 412.433 371.135 412.433 362.238C412.42 359.294 413.213 356.402 414.726 353.876C416.239 351.349 418.414 349.284 421.017 347.904Z"
        fill="url(#paint23_linear)"
      />
      <path
        d="M423.294 347.812C421.467 345.724 420.11 343.267 419.315 340.609C418.519 337.951 418.304 335.154 418.684 332.406C419.064 329.658 420.029 327.023 421.516 324.68C423.002 322.337 424.975 320.34 427.3 318.824C426.096 316.966 425.458 314.799 425.463 312.586C425.393 309.882 426.317 307.246 428.06 305.177C429.804 303.108 432.246 301.75 434.924 301.358C437.602 300.966 440.332 301.569 442.595 303.052C444.859 304.536 446.5 306.796 447.208 309.407C448.651 306.931 450.717 304.875 453.2 303.443C455.683 302.011 458.498 301.252 461.365 301.242C470.521 301.242 477.897 308.806 477.897 318.138C477.899 320.125 477.556 322.099 476.884 323.97C477.222 323.97 477.553 323.918 477.897 323.918C485.227 323.918 491.167 329.99 491.167 337.48C491.167 337.944 491.167 338.401 491.098 338.847C494.082 340.183 496.612 342.357 498.382 345.104C500.152 347.851 501.084 351.052 501.067 354.319C501.067 363.622 493.691 371.158 484.586 371.158C483.627 371.157 482.669 371.071 481.725 370.9C481.777 371.472 481.805 372.044 481.805 372.616C481.805 383.05 473.525 391.513 463.311 391.513C459.535 391.513 455.854 390.336 452.781 388.145C450.309 390.312 447.133 391.509 443.843 391.513C436.215 391.513 430.029 385.194 430.029 377.396C430.029 377.058 430.029 376.727 430.081 376.395H430.029C421.858 376.395 415.237 369.625 415.237 361.277C415.223 358.512 415.966 355.796 417.387 353.422C418.807 351.049 420.85 349.109 423.294 347.812Z"
        fill="#00897B"
      />
      <path
        d="M456.759 326.245H452.461V433H456.759V326.245Z"
        fill="url(#paint24_linear)"
      />
      <path d="M455.54 327.743H453.377V433H455.54V327.743Z" fill="#795548" />
      <path
        d="M438.008 339.676L436.421 341.145L454.151 360.275L455.738 358.807L438.008 339.676Z"
        fill="#795548"
      />
      <path
        d="M472.685 361.423L454.299 375.066L455.589 376.801L473.974 363.158L472.685 361.423Z"
        fill="#795548"
      />
      <path
        d="M447.98 359.774C450.881 356.689 452.497 352.617 452.501 348.384C452.488 345.461 451.717 342.591 450.263 340.055C448.808 337.519 446.721 335.402 444.204 333.913C445.326 332.274 445.925 330.335 445.92 328.349C445.92 322.769 441.234 318.24 435.454 318.24C433.221 318.218 431.04 318.912 429.232 320.221C427.424 321.531 426.085 323.386 425.411 325.513C423.984 323.27 422.009 321.426 419.673 320.154C417.337 318.882 414.716 318.223 412.056 318.24C403.438 318.24 396.456 324.982 396.456 333.301C396.455 335.077 396.779 336.838 397.412 338.498C397.097 338.498 396.777 338.452 396.456 338.452C389.544 338.452 383.936 343.861 383.936 350.54C383.936 350.946 383.957 351.353 383.999 351.757C381.242 352.869 378.877 354.772 377.203 357.227C375.529 359.682 374.622 362.578 374.597 365.549C374.597 373.839 381.561 380.557 390.145 380.557C391.046 380.556 391.946 380.48 392.834 380.329C392.788 380.826 392.76 381.335 392.76 381.844C392.76 391.147 400.571 398.688 410.207 398.688C413.744 398.697 417.202 397.652 420.141 395.686C422.522 397.653 425.515 398.727 428.605 398.723C435.803 398.723 441.64 393.091 441.64 386.144C441.64 385.841 441.64 385.572 441.594 385.252H441.64C449.348 385.252 455.597 379.219 455.597 371.775C455.578 369.263 454.855 366.806 453.508 364.685C452.162 362.563 450.247 360.862 447.98 359.774Z"
        fill="url(#paint25_linear)"
      />
      <path
        d="M446.573 359.722C449.357 356.772 450.912 352.873 450.922 348.819C450.906 346.024 450.166 343.281 448.774 340.858C447.382 338.434 445.386 336.411 442.979 334.988C444.059 333.422 444.637 331.566 444.639 329.664C444.639 324.324 440.152 319.99 434.619 319.99C432.481 319.968 430.393 320.633 428.662 321.887C426.931 323.141 425.649 324.917 425.005 326.954C423.639 324.807 421.749 323.041 419.513 321.823C417.277 320.605 414.768 319.974 412.221 319.99C403.976 319.99 397.292 326.445 397.292 334.404C397.291 336.104 397.602 337.79 398.207 339.379C397.904 339.379 397.601 339.333 397.292 339.333C390.671 339.333 385.309 344.513 385.309 350.9C385.311 351.282 385.332 351.663 385.372 352.043C382.734 353.107 380.47 354.928 378.868 357.277C377.266 359.626 376.397 362.397 376.371 365.24C376.371 373.176 383.032 379.608 391.249 379.608C392.112 379.608 392.973 379.535 393.824 379.391C393.778 379.871 393.755 380.352 393.755 380.843C393.755 389.746 401.235 396.967 410.453 396.967C413.837 396.98 417.149 395.985 419.964 394.108C422.242 395.969 425.096 396.979 428.038 396.967C434.905 396.967 440.513 391.575 440.513 384.926C440.513 384.64 440.513 384.354 440.467 384.074H440.513C447.889 384.074 453.874 378.299 453.874 371.175C453.849 368.775 453.151 366.43 451.861 364.406C450.57 362.382 448.739 360.76 446.573 359.722Z"
        fill="#009688"
      />
      <path
        d="M420.794 342.861H415.907V432.463H420.794V342.861Z"
        fill="url(#paint26_linear)"
      />
      <path
        d="M419.615 342.861H416.914V432.463H419.615V342.861Z"
        fill="#795548"
      />
      <path
        d="M433.041 352.433L417.03 368.753L418.958 370.642L434.97 354.322L433.041 352.433Z"
        fill="#795548"
      />
      <path
        d="M402.168 370.987L400.616 373.196L417.217 384.836L418.768 382.627L402.168 370.987Z"
        fill="#795548"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="405.715"
          y1="135.818"
          x2="439.477"
          y2="135.818"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="51799.3"
          y1="6918.4"
          x2="51799.3"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="75847.1"
          y1="128490"
          x2="75847.1"
          y2="36406.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="2217.5"
          y1="168391"
          x2="2217.5"
          y2="54907.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="101957"
          y1="110954"
          x2="101957"
          y2="46510.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="4355.04"
          y1="140911"
          x2="4355.04"
          y2="60966.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="223914"
          y1="171992"
          x2="223914"
          y2="89333.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="37747.4"
          y1="25024.8"
          x2="37747.4"
          y2="17240.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="23810.6"
          y1="31508.8"
          x2="23810.6"
          y2="25427.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="42867"
          y1="31508.8"
          x2="42867"
          y2="25427.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="209062"
          y1="81311.3"
          x2="209062"
          y2="19064.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1="40715.1"
          y1="70908.9"
          x2="40715.1"
          y2="53913"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint12_linear"
          x1="8804.99"
          y1="9555.83"
          x2="8804.99"
          y2="9198.48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint13_linear"
          x1="49209.8"
          y1="21599"
          x2="49209.8"
          y2="20169.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint14_linear"
          x1="42759.7"
          y1="77073.2"
          x2="42759.7"
          y2="57502.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint15_linear"
          x1="1449.26"
          y1="87203.5"
          x2="1449.26"
          y2="63090.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint16_linear"
          x1="45311.1"
          y1="65274.3"
          x2="45311.1"
          y2="51576.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint17_linear"
          x1="2040.5"
          y1="73155.4"
          x2="2040.5"
          y2="56168.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint18_linear"
          x1="1185.89"
          y1="120051"
          x2="1185.89"
          y2="75224.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint19_linear"
          x1="22886.5"
          y1="15051.8"
          x2="22886.5"
          y2="13685.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint20_linear"
          x1="439.7"
          y1="4965.19"
          x2="439.7"
          y2="4818.81"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint21_linear"
          x1="2523.63"
          y1="4965.19"
          x2="2523.63"
          y2="4818.81"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint22_linear"
          x1="7493.75"
          y1="42119.8"
          x2="24335.3"
          y2="42119.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint23_linear"
          x1="-45520.7"
          y1="72415.5"
          x2="-45520.7"
          y2="56240.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint24_linear"
          x1="-1885.17"
          y1="87542.7"
          x2="-1885.17"
          y2="67610.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint25_linear"
          x1="-46350.7"
          y1="61271.8"
          x2="-46350.7"
          y2="49947.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint26_linear"
          x1="-2456.95"
          y1="73455.8"
          x2="-2456.95"
          y2="59414.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
