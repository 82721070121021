import { useEffect } from 'react';

import { PortalRoutes } from 'routes/portal.enums';

export const TriadTransferPage = () => {
  useEffect(() => {
    window.location.href = PortalRoutes.TriadTransferPage;
  }, []);

  return <div></div>;
};
