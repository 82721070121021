import React from 'react';

import { Bell } from 'lucide-react';

import { PortalRoutes } from 'routes/portal.enums';

export const AlertBanner: React.FC = () => {
  return (
    <a
      href={PortalRoutes.TriadTransferPage}
      className="block bg-yellow-100 p-4 cursor-pointer hover:bg-yellow-200 transition-colors duration-200"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-center space-x-2">
          <Bell className="h-5 w-5 text-yellow-600" />
          <span className="text-yellow-800 font-medium">
            Hillcrest Acres and Metro Commons residents click here
          </span>
        </div>
      </div>
    </a>
  );
};
