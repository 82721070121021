export const CheckListForm = (): JSX.Element => {
  return (
    <svg
      width="139"
      height="107"
      viewBox="0 0 139 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.0162 106.462C41.9578 106.913 38.8627 107.063 35.7751 106.909C28.3302 106.533 21.0085 104.368 14.7513 100.296C11.9771 98.491 9.33059 96.273 7.89455 93.2937C5.94551 89.2476 6.62714 84.1256 9.4104 80.6015C10.4256 79.3303 11.6744 78.2649 13.0895 77.4626C13.2992 77.344 13.5135 77.2301 13.7301 77.1207C16.0881 75.9348 18.7367 75.4499 21.3619 75.7233C25.825 76.2111 29.9442 78.9374 32.3239 82.7465C34.4438 86.1429 35.1824 90.1503 35.6179 94.1668C35.6999 94.9235 35.7706 95.6826 35.8389 96.4371C35.4596 98.6231 34.8632 100.766 34.0586 102.833C34.0177 102.934 33.9842 103.038 33.9583 103.143C34.4142 104.133 34.8481 105.131 35.2599 106.139C35.6469 106.349 36.0803 106.458 36.5205 106.458H44.998L45.0162 106.462Z"
        fill="#F2F2F2"
      />
      <path
        d="M35.0845 106.656C35.0283 106.681 34.9677 106.694 34.9062 106.696C34.8446 106.697 34.7834 106.686 34.7262 106.664C34.6689 106.641 34.6166 106.608 34.5725 106.565C34.5284 106.522 34.4932 106.471 34.4691 106.414C34.4326 106.327 34.3984 106.241 34.3619 106.154C34.0337 105.356 33.6895 104.566 33.3294 103.784C28.7184 93.6841 21.8407 84.7838 13.2309 77.7744C13.1816 77.7398 13.1425 77.6925 13.1176 77.6376C13.0928 77.5827 13.0831 77.5221 13.0897 77.4621C13.1022 77.3935 13.1301 77.3285 13.1712 77.2721C13.2124 77.2156 13.2657 77.1693 13.3273 77.1364C13.3889 77.1035 13.4572 77.085 13.5269 77.0822C13.5967 77.0794 13.6662 77.0924 13.7302 77.1202C13.7646 77.1358 13.7967 77.1557 13.826 77.1795C17.5096 80.1797 20.8889 83.5354 23.915 87.1979C26.9417 90.857 29.6067 94.8007 31.8727 98.9738C32.4905 100.114 33.077 101.269 33.6324 102.441C33.742 102.673 33.8514 102.908 33.9585 103.143C34.4144 104.132 34.8482 105.131 35.2601 106.138C35.267 106.154 35.2737 106.172 35.2806 106.188C35.3016 106.232 35.3132 106.279 35.3145 106.327C35.3158 106.375 35.3069 106.422 35.2883 106.466C35.2698 106.511 35.242 106.55 35.2068 106.583C35.1717 106.616 35.13 106.64 35.0845 106.656Z"
        fill="white"
      />
      <path
        d="M3.97004 71.0927C3.77003 71.0931 3.57531 71.0284 3.41528 70.9085L3.40535 70.901L1.31613 69.3014C1.21931 69.2273 1.13804 69.1349 1.07697 69.0293C1.01589 68.9238 0.976202 68.8073 0.960173 68.6864C0.944145 68.5655 0.952087 68.4427 0.983547 68.3249C1.01501 68.2071 1.06937 68.0966 1.14353 67.9999C1.21768 67.9031 1.31018 67.8218 1.41574 67.7608C1.5213 67.6998 1.63784 67.6602 1.75872 67.6442C1.8796 67.6282 2.00244 67.6362 2.12023 67.6677C2.23801 67.6992 2.34844 67.7537 2.44519 67.8279L3.79842 68.8655L6.99634 64.695C7.07051 64.5982 7.163 64.517 7.26854 64.4561C7.37408 64.3951 7.49059 64.3555 7.61144 64.3395C7.73228 64.3236 7.85508 64.3316 7.97283 64.3631C8.09058 64.3946 8.20097 64.449 8.2977 64.5232L8.27782 64.5502L8.29822 64.5236C8.49333 64.6735 8.621 64.8948 8.65321 65.1387C8.68541 65.3827 8.61953 65.6295 8.47001 65.8249L4.70848 70.7305C4.62149 70.8435 4.50962 70.935 4.38156 70.9978C4.25349 71.0607 4.11268 71.0931 3.97004 71.0927Z"
        fill="white"
      />
      <path
        d="M138.807 106.908H1.05561C1.00431 106.908 0.955159 106.884 0.918934 106.841C0.882709 106.799 0.862366 106.741 0.862366 106.68C0.862366 106.62 0.882709 106.562 0.918934 106.519C0.955159 106.477 1.00431 106.453 1.05561 106.452H138.807C138.858 106.453 138.907 106.477 138.943 106.519C138.98 106.562 139 106.62 139 106.68C139 106.741 138.98 106.799 138.943 106.841C138.907 106.884 138.858 106.908 138.807 106.908Z"
        fill="#3F3D56"
      />
      <path
        d="M80.3026 46.7296C81.9392 46.7296 83.266 45.4029 83.266 43.7663C83.266 42.1297 81.9392 40.8029 80.3026 40.8029C78.666 40.8029 77.3393 42.1297 77.3393 43.7663C77.3393 45.4029 78.666 46.7296 80.3026 46.7296Z"
        fill="#005CFF"
      />
      <path
        d="M80.0089 44.8969C79.9422 44.897 79.8773 44.8754 79.824 44.8354L79.8207 44.833L79.1242 44.2998C79.0919 44.2751 79.0647 44.2443 79.0443 44.2091C79.0238 44.1739 79.0105 44.135 79.0051 44.0947C78.9997 44.0543 79.0024 44.0133 79.0128 43.974C79.0233 43.9347 79.0415 43.8978 79.0662 43.8655C79.091 43.8332 79.1218 43.8061 79.1571 43.7858C79.1923 43.7654 79.2312 43.7522 79.2716 43.7469C79.3119 43.7416 79.3529 43.7444 79.3922 43.7549C79.4315 43.7655 79.4684 43.7837 79.5006 43.8086L79.9517 44.1544L81.0176 42.7643C81.0424 42.732 81.0732 42.705 81.1084 42.6846C81.1436 42.6643 81.1824 42.6511 81.2227 42.6458C81.263 42.6405 81.3039 42.6431 81.3431 42.6536C81.3824 42.6641 81.4192 42.6823 81.4514 42.707L81.4448 42.716L81.4516 42.7071C81.5166 42.7571 81.5592 42.8309 81.5699 42.9122C81.5807 42.9935 81.5587 43.0758 81.5089 43.1409L80.255 44.7761C80.226 44.8138 80.1887 44.8443 80.1461 44.8652C80.1034 44.8862 80.0564 44.897 80.0089 44.8969Z"
        fill="white"
      />
      <path
        d="M80.3026 55.3915C81.9392 55.3915 83.266 54.0647 83.266 52.4281C83.266 50.7915 81.9392 49.4648 80.3026 49.4648C78.666 49.4648 77.3393 50.7915 77.3393 52.4281C77.3393 54.0647 78.666 55.3915 80.3026 55.3915Z"
        fill="#005CFF"
      />
      <path
        d="M80.0089 53.5587C79.9422 53.5589 79.8773 53.5373 79.824 53.4973L79.8207 53.4948L79.1242 52.9616C79.0919 52.9369 79.0647 52.9061 79.0443 52.8709C79.0238 52.8358 79.0105 52.7969 79.0051 52.7565C78.9997 52.7162 79.0024 52.6752 79.0128 52.6359C79.0233 52.5966 79.0415 52.5597 79.0662 52.5274C79.091 52.4951 79.1218 52.468 79.1571 52.4476C79.1923 52.4273 79.2312 52.4141 79.2716 52.4088C79.3119 52.4035 79.3529 52.4062 79.3922 52.4168C79.4315 52.4274 79.4684 52.4456 79.5006 52.4704L79.9517 52.8163L81.0176 51.4261C81.0424 51.3939 81.0732 51.3668 81.1084 51.3465C81.1436 51.3262 81.1824 51.313 81.2227 51.3077C81.263 51.3023 81.3039 51.305 81.3431 51.3155C81.3824 51.326 81.4192 51.3441 81.4514 51.3689L81.4448 51.3779L81.4516 51.369C81.5166 51.419 81.5592 51.4927 81.5699 51.5741C81.5807 51.6554 81.5587 51.7376 81.5089 51.8028L80.255 53.438C80.226 53.4757 80.1887 53.5062 80.1461 53.5271C80.1034 53.548 80.0564 53.5589 80.0089 53.5587Z"
        fill="white"
      />
      <path
        d="M81.8983 65.422C83.5349 65.422 84.8617 64.0953 84.8617 62.4586C84.8617 60.822 83.5349 59.4953 81.8983 59.4953C80.2617 59.4953 78.935 60.822 78.935 62.4586C78.935 64.0953 80.2617 65.422 81.8983 65.422Z"
        fill="#005CFF"
      />
      <path
        d="M81.6046 63.5886C81.5379 63.5888 81.473 63.5672 81.4196 63.5272L81.4163 63.5247L80.7199 62.9915C80.6876 62.9669 80.6604 62.936 80.6399 62.9009C80.6195 62.8657 80.6062 62.8268 80.6008 62.7864C80.5954 62.7461 80.598 62.7051 80.6085 62.6658C80.619 62.6265 80.6371 62.5896 80.6619 62.5573C80.6866 62.525 80.7175 62.4979 80.7528 62.4775C80.788 62.4572 80.8269 62.444 80.8673 62.4387C80.9076 62.4334 80.9486 62.4361 80.9879 62.4467C81.0272 62.4573 81.064 62.4755 81.0963 62.5003L81.5474 62.8462L82.6133 61.456C82.6381 61.4238 82.6689 61.3967 82.7041 61.3764C82.7392 61.3561 82.7781 61.3429 82.8184 61.3376C82.8586 61.3322 82.8996 61.3349 82.9388 61.3454C82.9781 61.3559 83.0149 61.3741 83.0471 61.3988L83.0405 61.4078L83.0473 61.3989C83.1123 61.4489 83.1549 61.5226 83.1656 61.604C83.1764 61.6853 83.1544 61.7675 83.1046 61.8327L81.8507 63.4679C81.8217 63.5056 81.7844 63.5361 81.7417 63.557C81.699 63.5779 81.6521 63.5888 81.6046 63.5886Z"
        fill="white"
      />
      <path
        d="M110.473 87.1999C110.443 87.1999 110.423 87.1991 110.416 87.1987L106.737 87.1991V86.6566H110.43C110.515 86.6597 112.259 86.7089 113.866 84.759C116.261 81.8528 118.228 74.6586 113.997 56.8889C108.308 32.995 113.053 11.8861 115.357 3.91967C115.47 3.52554 115.491 3.1104 115.416 2.70707C115.342 2.30374 115.174 1.92326 114.928 1.5957C114.681 1.26813 114.361 1.00246 113.994 0.819663C113.627 0.63687 113.222 0.541971 112.812 0.542457H57.8683C57.4505 0.543017 57.0388 0.642061 56.6666 0.831551C56.2943 1.02104 55.9719 1.29564 55.7257 1.63306C54.7496 2.97568 53.4102 5.55498 52.572 10.2188L52.0382 10.1229C52.8945 5.35778 54.2775 2.7026 55.2869 1.31415C55.5836 0.90758 55.9719 0.576696 56.4204 0.348361C56.8689 0.120026 57.365 0.000675326 57.8683 1.47635e-06H112.812C113.306 -0.00047418 113.794 0.113994 114.236 0.334361C114.679 0.554729 115.064 0.874959 115.361 1.26975C115.659 1.66454 115.86 2.12308 115.95 2.60913C116.039 3.09519 116.015 3.59545 115.878 4.07038C113.586 11.9955 108.866 32.9949 114.525 56.7633C118.815 74.7829 116.753 82.133 114.267 85.1259C112.627 87.0999 110.821 87.1999 110.473 87.1999Z"
        fill="#E6E6E6"
      />
      <path
        d="M100.574 97.1003C100.543 97.1003 100.524 97.0995 100.517 97.0991L96.8378 97.0995V96.5571H100.53C100.616 96.5597 102.36 96.6094 103.967 94.6594C106.362 91.7533 108.328 84.559 104.097 66.7893C98.4084 42.8955 103.153 21.7866 105.457 13.8201C105.571 13.426 105.591 13.0109 105.517 12.6075C105.442 12.2042 105.275 11.8237 105.028 11.4962C104.781 11.1686 104.462 10.9029 104.094 10.7201C103.727 10.5373 103.323 10.4424 102.912 10.4429H47.9687C47.5509 10.4435 47.1392 10.5425 46.767 10.732C46.3947 10.9215 46.0723 11.1961 45.8261 11.5335C44.85 12.8761 43.5106 15.4554 42.6724 20.1192L42.1385 20.0234C42.9949 15.2582 44.3779 12.603 45.3873 11.2146C45.684 10.808 46.0723 10.4771 46.5208 10.2488C46.9693 10.0205 47.4654 9.90113 47.9687 9.90045H102.912C103.407 9.89998 103.894 10.0144 104.337 10.2348C104.779 10.4552 105.164 10.7754 105.462 11.1702C105.759 11.565 105.96 12.0235 106.05 12.5096C106.14 12.9956 106.115 13.4959 105.978 13.9708C103.686 21.896 98.966 42.8954 104.625 66.6638C108.916 84.6834 106.853 92.0335 104.367 95.0264C102.728 97.0003 100.921 97.1003 100.574 97.1003Z"
        fill="#CCCCCC"
      />
      <path
        d="M94.7254 76.564C89.0654 52.7956 93.7862 31.7946 96.0794 23.8711C96.216 23.3961 96.2403 22.8959 96.1505 22.41C96.0606 21.924 95.8591 21.4656 95.5618 21.0708C95.2645 20.676 94.8796 20.3557 94.4373 20.1352C93.9951 19.9146 93.5076 19.7998 93.0135 19.7999H38.0685C37.5654 19.8004 37.0696 19.9195 36.6212 20.1477C36.1728 20.3758 35.7846 20.7065 35.488 21.1129C33.2701 24.1628 28.6678 34.277 33.8081 62.2624C36.5571 77.2341 36.5162 87.4417 35.618 94.1663C35.1142 97.9525 34.3369 100.633 33.6325 102.441L33.5574 102.632C33.409 102.998 33.3316 103.389 33.3294 103.783C33.3242 104.229 33.4136 104.671 33.5916 105.08C33.7695 105.488 34.0321 105.854 34.362 106.154C34.5771 106.356 34.8205 106.525 35.0846 106.656C35.3038 106.767 35.5357 106.852 35.7753 106.909C36.0193 106.968 36.2696 106.998 36.5207 106.998H90.6178C90.6247 106.998 90.6428 107 90.6747 107C91.0212 107 92.8289 106.9 94.4679 104.926C96.9525 101.933 99.0154 94.5834 94.7254 76.564ZM94.0666 104.559C92.4596 106.51 90.7158 106.46 90.6314 106.457H36.5207C36.0875 106.457 35.6609 106.351 35.2782 106.148C34.8956 105.945 34.5684 105.651 34.3255 105.292C34.1118 104.982 33.9682 104.629 33.9048 104.258C33.8415 103.887 33.8598 103.506 33.9585 103.143C33.9844 103.037 34.0179 102.934 34.0588 102.833C34.8634 100.765 35.4598 98.6226 35.8391 96.4366C37.1248 89.4272 37.3641 78.611 34.3415 62.1644C29.24 34.3864 33.7511 24.425 35.9257 21.432C36.1724 21.0951 36.4949 20.8209 36.8671 20.6316C37.2394 20.4423 37.6509 20.3433 38.0685 20.3424H93.0135C93.4234 20.3421 93.8278 20.4371 94.1947 20.6199C94.5616 20.8027 94.8811 21.0682 95.1278 21.3956C95.3745 21.723 95.5417 22.1033 95.6163 22.5063C95.6908 22.9094 95.6707 23.3244 95.5574 23.7183C93.2529 31.6852 88.5092 52.7956 94.1988 76.6893C98.4296 94.458 96.4624 101.652 94.0666 104.559Z"
        fill="#3F3D56"
      />
      <path
        d="M68.6223 45.6816H40.3141C39.7386 45.6816 39.1867 45.453 38.7798 45.0461C38.3729 44.6392 38.1443 44.0873 38.1443 43.5118C38.1443 42.9363 38.3729 42.3844 38.7798 41.9775C39.1867 41.5706 39.7386 41.342 40.3141 41.342H68.6223C69.1977 41.342 69.7496 41.5706 70.1566 41.9775C70.5635 42.3844 70.7921 42.9363 70.7921 43.5118C70.7921 44.0873 70.5635 44.6392 70.1566 45.0461C69.7496 45.453 69.1977 45.6816 68.6223 45.6816Z"
        fill="#CCCCCC"
      />
      <path
        d="M68.6223 55.0272H40.3141C39.7386 55.0272 39.1867 54.7986 38.7798 54.3917C38.3729 53.9848 38.1443 53.4329 38.1443 52.8574C38.1443 52.282 38.3729 51.7301 38.7798 51.3231C39.1867 50.9162 39.7386 50.6876 40.3141 50.6876H68.6223C69.1977 50.6876 69.7496 50.9162 70.1566 51.3231C70.5635 51.7301 70.7921 52.282 70.7921 52.8574C70.7921 53.4329 70.5635 53.9848 70.1566 54.3917C69.7496 54.7986 69.1977 55.0272 68.6223 55.0272Z"
        fill="#CCCCCC"
      />
      <path
        d="M70.902 64.6013H42.5938C42.0183 64.6013 41.4664 64.3727 41.0595 63.9658C40.6526 63.5589 40.424 63.007 40.424 62.4315C40.424 61.8561 40.6526 61.3042 41.0595 60.8972C41.4664 60.4903 42.0183 60.2617 42.5938 60.2617H70.902C71.4774 60.2617 72.0293 60.4903 72.4363 60.8972C72.8432 61.3042 73.0718 61.8561 73.0718 62.4315C73.0718 63.007 72.8432 63.5589 72.4363 63.9658C72.0293 64.3727 71.4774 64.6013 70.902 64.6013Z"
        fill="#CCCCCC"
      />
      <path
        d="M72.4974 74.6307H44.1892C43.6137 74.6307 43.0618 74.4021 42.6549 73.9952C42.248 73.5882 42.0194 73.0364 42.0194 72.4609C42.0194 71.8854 42.248 71.3335 42.6549 70.9266C43.0618 70.5197 43.6137 70.2911 44.1892 70.2911H72.4974C73.0729 70.2911 73.6248 70.5197 74.0317 70.9266C74.4386 71.3335 74.6672 71.8854 74.6672 72.4609C74.6672 73.0364 74.4386 73.5882 74.0317 73.9952C73.6248 74.4021 73.0729 74.6307 72.4974 74.6307Z"
        fill="#CCCCCC"
      />
      <path
        d="M72.5099 28.8332H52.1679C51.8828 28.8335 51.6004 28.7776 51.3369 28.6687C51.0734 28.5598 50.8339 28.4 50.6322 28.1985C50.4305 27.997 50.2705 27.7577 50.1613 27.4943C50.0521 27.2309 49.9959 26.9485 49.9959 26.6634C49.9959 26.3783 50.0521 26.0959 50.1613 25.8325C50.2705 25.5691 50.4305 25.3298 50.6322 25.1283C50.8339 24.9268 51.0734 24.767 51.3369 24.6581C51.6004 24.5492 51.8828 24.4933 52.1679 24.4936H72.5099C73.0854 24.4936 73.6373 24.7222 74.0442 25.1291C74.4511 25.536 74.6797 26.0879 74.6797 26.6634C74.6797 27.2389 74.4511 27.7908 74.0442 28.1977C73.6373 28.6046 73.0854 28.8332 72.5099 28.8332Z"
        fill="#005CFF"
      />
      <path
        d="M83.7222 75.4247C85.3588 75.4247 86.6855 74.0979 86.6855 72.4613C86.6855 70.8247 85.3588 69.498 83.7222 69.498C82.0856 69.498 80.7588 70.8247 80.7588 72.4613C80.7588 74.0979 82.0856 75.4247 83.7222 75.4247Z"
        fill="#CCCCCC"
      />
      <path
        d="M73.8649 84.4328H45.5567C44.9812 84.4328 44.4293 84.2041 44.0224 83.7972C43.6155 83.3903 43.3869 82.8384 43.3869 82.2629C43.3869 81.6875 43.6155 81.1356 44.0224 80.7287C44.4293 80.3217 44.9812 80.0931 45.5567 80.0931H73.8649C74.4404 80.0931 74.9922 80.3217 75.3992 80.7287C75.8061 81.1356 76.0347 81.6875 76.0347 82.2629C76.0347 82.8384 75.8061 83.3903 75.3992 83.7972C74.9922 84.2041 74.4404 84.4328 73.8649 84.4328Z"
        fill="#CCCCCC"
      />
      <path
        d="M85.0897 85.2267C86.7263 85.2267 88.053 83.9 88.053 82.2634C88.053 80.6268 86.7263 79.3 85.0897 79.3C83.4531 79.3 82.1263 80.6268 82.1263 82.2634C82.1263 83.9 83.4531 85.2267 85.0897 85.2267Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};
