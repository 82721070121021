export const Door = (): JSX.Element => {
  return (
    <svg
      width="196"
      height="117"
      viewBox="0 0 196 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M149.842 115.686C147.336 113.568 145.814 110.224 146.213 106.968C146.612 103.712 149.085 100.719 152.291 100.027C155.498 99.3357 159.153 101.25 160.127 104.382C160.663 98.3428 161.281 92.0505 164.492 86.9075C167.399 82.2507 172.435 78.9185 177.892 78.3224C183.35 77.7262 189.096 79.9777 192.499 84.2852C195.903 88.5927 196.736 94.8549 194.353 99.8003C192.597 103.443 189.361 106.155 185.971 108.36C175.018 115.428 161.88 118.302 148.976 116.454L149.842 115.686Z"
        fill="#F2F2F2"
      />
      <path
        d="M187.102 80.1025C182.599 83.7703 178.468 87.8725 174.769 92.3497C168.979 99.3325 164.294 107.161 160.877 115.564C160.627 116.177 161.623 116.444 161.87 115.838C167.461 102.182 176.387 90.1458 187.83 80.8303C188.344 80.4119 187.612 79.6875 187.102 80.1025Z"
        fill="white"
      />
      <path
        d="M63.0026 115.11C66.4441 112.202 68.5345 107.609 67.9863 103.136C67.4381 98.6642 64.0426 94.5529 59.6382 93.6033C55.2338 92.6536 50.2131 95.2824 48.8754 99.5849C48.1391 91.29 47.2907 82.6475 42.8806 75.5835C38.8874 69.1873 31.971 64.6106 24.4752 63.7918C16.9793 62.973 9.08721 66.0654 4.41234 71.9818C-0.262524 77.8982 -1.40714 86.4993 1.86701 93.2918C4.27896 98.2955 8.72264 102.019 13.3785 105.049C28.2323 114.714 46.6584 118.745 64.1912 116.165"
        fill="#F2F2F2"
      />
      <path
        d="M11.825 66.2368C18.01 71.2746 23.684 76.9089 28.7651 83.0583C33.847 89.2014 38.321 95.823 42.1244 102.83C44.2503 106.761 46.1601 110.804 47.8451 114.943C48.1887 115.785 46.8216 116.151 46.4818 115.319C43.4971 108.022 39.8045 101.036 35.4569 94.4593C31.1304 87.9068 26.1785 81.7895 20.6708 76.1932C17.5556 73.0294 14.2688 70.0394 10.8253 67.2366C10.1194 66.6618 11.125 65.6668 11.825 66.2369L11.825 66.2368Z"
        fill="white"
      />
      <path
        d="M140.485 116.753H58.3758V32.5063C58.3758 14.5822 72.958 0 90.882 0H107.979C125.903 0 140.485 14.5822 140.485 32.5063V116.753Z"
        fill="#F2F2F2"
      />
      <path
        d="M123.388 116.753H58.3757V32.5063C58.3577 25.6382 60.5325 18.9437 64.5835 13.3975C64.7328 13.194 64.8818 12.9931 65.0354 12.7944C66.9948 10.2245 69.3266 7.96119 71.9538 6.07926C72.1022 5.97128 72.2511 5.86518 72.4023 5.75907C74.6007 4.23778 76.9787 2.99415 79.4822 2.0565C79.6309 2.00015 79.7823 1.94369 79.9336 1.88954C82.1979 1.08151 84.545 0.528016 86.9317 0.239273C87.0796 0.219061 87.2315 0.203245 87.3835 0.187428C89.7093 -0.0612552 92.055 -0.0612552 94.3809 0.187428C94.5323 0.203245 94.6842 0.219059 94.834 0.23949C97.22 0.528251 99.5665 1.08168 101.83 1.88955C101.982 1.9437 102.133 2.00016 102.282 2.05662C104.755 2.98244 107.105 4.20751 109.28 5.70448C109.431 5.80828 109.582 5.91439 109.731 6.0205C111.204 7.07166 112.587 8.24272 113.867 9.52172C114.893 10.5476 115.849 11.6412 116.729 12.795C116.882 12.9931 117.031 13.194 117.18 13.3972C121.231 18.9435 123.406 25.6381 123.388 32.5063V116.753Z"
        fill="#CCCCCC"
      />
      <path
        d="M118.102 79.0722C120.09 79.0722 121.701 77.4607 121.701 75.4728C121.701 73.485 120.09 71.8735 118.102 71.8735C116.114 71.8735 114.503 73.485 114.503 75.4728C114.503 77.4607 116.114 79.0722 118.102 79.0722Z"
        fill="#005CFF"
      />
      <path
        d="M133.759 114.229H131.001L129.689 103.592L133.759 103.592L133.759 114.229Z"
        fill="#FFB8B8"
      />
      <path
        d="M129.031 113.441H134.35V116.79H125.682C125.682 116.35 125.768 115.915 125.937 115.509C126.105 115.102 126.352 114.733 126.663 114.422C126.974 114.111 127.343 113.864 127.749 113.696C128.155 113.528 128.591 113.441 129.031 113.441Z"
        fill="#2F2E41"
      />
      <path
        d="M147.481 114.229H144.723L143.411 103.592L147.482 103.592L147.481 114.229Z"
        fill="#FFB8B8"
      />
      <path
        d="M142.753 113.441H148.072V116.79H139.404C139.404 116.35 139.491 115.915 139.659 115.509C139.827 115.102 140.074 114.733 140.385 114.422C140.696 114.111 141.065 113.864 141.471 113.696C141.878 113.528 142.313 113.441 142.753 113.441Z"
        fill="#2F2E41"
      />
      <path
        d="M119.923 76.1864C119.725 75.8941 119.593 75.5618 119.537 75.213C119.481 74.8642 119.502 74.5073 119.599 74.1675C119.695 73.8277 119.865 73.5133 120.097 73.2463C120.328 72.9794 120.615 72.7664 120.938 72.6224L130.313 46.7823L134.926 49.2999L124.222 73.9936C124.428 74.5516 124.422 75.1658 124.205 75.7198C123.989 76.2738 123.576 76.729 123.047 76.9993C122.517 77.2696 121.906 77.3362 121.331 77.1863C120.755 77.0365 120.254 76.6807 119.923 76.1864Z"
        fill="#FFB8B8"
      />
      <path
        d="M146.072 81.0416C145.766 80.8658 145.502 80.625 145.298 80.3361C145.095 80.0472 144.957 79.7173 144.895 79.3695C144.833 79.0217 144.848 78.6645 144.939 78.3231C145.03 77.9817 145.195 77.6644 145.421 77.3935L142.517 50.0591L147.767 50.299L148.974 77.1859C149.404 77.5972 149.668 78.1517 149.716 78.7446C149.764 79.3375 149.593 79.9274 149.236 80.4027C148.878 80.8779 148.358 81.2055 147.775 81.3232C147.192 81.441 146.586 81.3407 146.072 81.0416Z"
        fill="#FFB8B8"
      />
      <path
        d="M137.697 41.9954C140.748 41.9954 143.222 39.5217 143.222 36.4702C143.222 33.4188 140.748 30.9451 137.697 30.9451C134.645 30.9451 132.172 33.4188 132.172 36.4702C132.172 39.5217 134.645 41.9954 137.697 41.9954Z"
        fill="#FFB8B8"
      />
      <path
        d="M146.037 74.8244H129.38L129.4 74.6948C129.43 74.501 132.369 55.2497 130.201 48.9516C129.984 48.3327 130.003 47.6552 130.255 47.0495C130.507 46.4438 130.974 45.9528 131.566 45.6709H131.567C134.665 44.212 140.611 42.4156 145.631 46.7753C146.369 47.4274 146.946 48.2424 147.316 49.1559C147.685 50.0693 147.837 51.0562 147.759 52.0384L146.037 74.8244Z"
        fill="#005CFF"
      />
      <path
        d="M132.579 58.095L125.079 56.5736L128.595 48.2434C128.805 47.4366 129.327 46.7462 130.046 46.3236C130.764 45.9009 131.621 45.7806 132.429 45.9891C133.236 46.1975 133.928 46.7176 134.352 47.4353C134.776 48.153 134.899 49.0098 134.692 49.8176L132.579 58.095Z"
        fill="#005CFF"
      />
      <path
        d="M142.184 60.9202L141.733 50.5854C141.391 48.6426 142.503 46.8061 144.213 46.5059C145.924 46.2068 147.595 47.5542 147.938 49.5113L149.633 59.1681L142.184 60.9202Z"
        fill="#005CFF"
      />
      <path
        d="M145.483 73.588C148.163 83.7958 148.456 96.7742 147.732 110.931L144.133 110.481L137.609 83.4861L134.01 110.931L129.961 110.706C128.751 95.8521 127.573 83.1003 129.511 74.7128L145.483 73.588Z"
        fill="#2F2E41"
      />
      <path
        d="M141.337 40.7407C140.307 41.8387 138.392 41.2493 138.258 39.7493C138.248 39.6328 138.248 39.5156 138.26 39.3993C138.33 38.7349 138.714 38.1317 138.622 37.4301C138.601 37.2555 138.536 37.0892 138.433 36.9467C137.611 35.8468 135.683 37.4387 134.908 36.443C134.433 35.8325 134.991 34.8712 134.627 34.1888C134.145 33.2882 132.719 33.7325 131.825 33.2392C130.83 32.6904 130.89 31.1639 131.545 30.2354C132.343 29.1031 133.744 28.4989 135.127 28.4118C136.51 28.3247 137.883 28.6986 139.174 29.2017C140.641 29.7734 142.096 30.5635 142.999 31.8534C144.096 33.4221 144.202 35.531 143.653 37.3653C143.319 38.481 142.179 39.8441 141.337 40.7407Z"
        fill="#2F2E41"
      />
      <path
        d="M188.959 116.898H22.8746C22.8037 116.897 22.7358 116.869 22.6858 116.819C22.6357 116.769 22.6076 116.701 22.6076 116.63C22.6076 116.559 22.6357 116.491 22.6858 116.441C22.7358 116.39 22.8037 116.362 22.8746 116.362H188.959C189.03 116.362 189.098 116.39 189.148 116.441C189.198 116.491 189.226 116.559 189.226 116.63C189.226 116.701 189.198 116.769 189.148 116.819C189.098 116.869 189.03 116.897 188.959 116.898Z"
        fill="#3F3D56"
      />
      <path
        d="M105.504 92.3448H76.26C75.8749 92.3468 75.5047 92.1961 75.2305 91.9255C74.9564 91.655 74.8008 91.2868 74.7978 90.9016V25.626C74.8008 25.2409 74.9564 24.8726 75.2305 24.6021C75.5047 24.3315 75.8749 24.1807 76.26 24.1828H105.504C105.89 24.1807 106.26 24.3315 106.534 24.6021C106.808 24.8726 106.964 25.2409 106.967 25.626V90.9016C106.964 91.2868 106.808 91.655 106.534 91.9255C106.26 92.1961 105.89 92.3468 105.504 92.3448Z"
        fill="white"
      />
      <path
        d="M106.967 45.3041H74.7978V45.754H106.967V45.3041Z"
        fill="#CCCCCC"
      />
      <path
        d="M107.079 70.9741H74.9102V71.424H107.079V70.9741Z"
        fill="#CCCCCC"
      />
      <path
        d="M85.3707 24.1828H84.9207V92.5697H85.3707V24.1828Z"
        fill="#CCCCCC"
      />
      <path
        d="M96.6186 24.1828H96.1687V92.5697H96.6186V24.1828Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};
