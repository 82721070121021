import { useState } from 'react';

import { Transition } from '@headlessui/react';

import {
  Construction,
  MountainHome,
  Refinance,
  SingleStory,
} from 'components/common/svg';

import { PaymentEstimator } from './payment-estimator/payment-estimator';

export const OurRates = (): JSX.Element => {
  const [paymentEstimatorVisible, setPaymentEstimatorVisible] = useState(false);

  return (
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900">
            Our Rates
          </h2>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            We're always working to get the best deal for our clients.
          </p>
        </div>

        <div className="my-10">
          <dl className="lg:grid-cols-4 lg:gap-x-8 lg:gap-y-10 grid grid-cols-2 gap-x-8">
            <div className="flex mb-10 lg:mb-0">
              <div className="flex-shrink-0">
                <Refinance />
                <div className="ml-4 mt-2">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Refinance
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    As low as 7.5%
                  </dd>
                </div>
              </div>
            </div>

            <div className="flex">
              <div className="w-full sm:w-auto flex-shrink-0">
                <Construction />
                <div
                  className="ml-4 mt-2"
                  style={{
                    marginTop: '1.3rem',
                  }}
                >
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    New Purchase
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    As low as 7.0%
                  </dd>
                </div>
              </div>
            </div>

            <div className="flex">
              <div className="flex-shrink-0">
                <SingleStory />
                <div
                  className="ml-4"
                  style={{
                    marginTop: '1.6rem',
                  }}
                >
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Pre-Owned
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    As low as 7.0%
                  </dd>
                </div>
              </div>
            </div>

            <div className="flex">
              <div className="w-full sm:w-auto flex-shrink-0">
                <MountainHome />
                <div
                  className="ml-4"
                  style={{
                    marginTop: '0.9rem',
                  }}
                >
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Home &amp; Land
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    As low as 6.0%
                  </dd>
                </div>
              </div>
            </div>
          </dl>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="my-4 text-2xl leading-8 font-extrabold tracking-tight text-gray-900">
            Try out the Payment Estimator Tool
          </h2>
        </div>
      </div>
      <div className="flex justify-center">
        <button
          className="capitalize bg-blue-600 flex items-center justify-center border border-transparent rounded-md py-2 px-4 text-base font-medium text-white hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          type="submit"
          onClick={() => setPaymentEstimatorVisible(!paymentEstimatorVisible)}
        >
          <span>See your options</span>
        </button>
        <Transition
          show={paymentEstimatorVisible}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <PaymentEstimator
            setPaymentEstimatorVisible={setPaymentEstimatorVisible}
          />
        </Transition>
      </div>
    </div>
  );
};
