import React from 'react';

import { SiteFooter } from './footer/site-footer';
import { SiteHeader } from './header/site-header';

interface HeaderFooterWrapperProps {
  children: React.ReactNode;
}

export const HeaderFooterWrapper = ({
  children,
}: React.PropsWithChildren<HeaderFooterWrapperProps>): JSX.Element => {
  return (
    <>
      <SiteHeader />
      {children}
      <SiteFooter />
    </>
  );
};
