import { HelpEmail } from 'common/constants';
import { HeaderFooterWrapper } from 'components/common/header-footer-wrapper/header-footer-wrapper';

export const ContactPage = (): JSX.Element => {
  return (
    <HeaderFooterWrapper>
      <div className="py-8 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
        <div className="max-w-max lg:max-w-7xl mx-auto">
          <div className="z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Questions?
              </h1>
            </div>
          </div>
          <div className="">
            <div className="md:bg-white md:p-6">
              <div className="prose prose-lg text-gray-900 lg:max-w-none">
                <p>
                  You can reach us at {HelpEmail} any time. If you want to stay
                  in touch, add your email to our list below. We'll be in touch
                  soon.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeaderFooterWrapper>
  );
};
