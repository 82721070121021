import { CreditScoreCategory, HomeType } from 'api/payment-estimator';

export interface PaymentEstimatorInputs {
  homeType?: HomeType;
  creditScoreBand?: CreditScoreCategory;
  haveCoapplicant?: boolean;
  homePrice?: number;
  downPaymentPercent?: number;
}

export function creditScoreBound(category: CreditScoreCategory) {
  if (category === CreditScoreCategory.Under600) {
    return 500;
  } else if (category === CreditScoreCategory.SixHundredToFiveFifty) {
    return 600;
  } else if (category === CreditScoreCategory.SixFiftyOneToSevenHundred) {
    return 650;
  } else if (category === CreditScoreCategory.SevenHundredToSevenFifty) {
    return 700;
  } else {
    return 750;
  }
}
