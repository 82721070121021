export const Options = (): JSX.Element => {
  return (
    <svg
      width="224"
      height="118"
      viewBox="0 0 224 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M217.677 62.8615C217.333 62.6628 217.037 62.3912 216.809 62.0657C216.582 61.7402 216.428 61.3688 216.36 60.9777C216.292 60.5865 216.31 60.1852 216.413 59.8018C216.517 59.4184 216.703 59.0624 216.959 58.7588L214.127 38.0566L220.027 38.3463L220.953 58.5391C221.435 59.0029 221.729 59.6273 221.781 60.2939C221.834 60.9606 221.639 61.6232 221.235 62.156C220.831 62.6889 220.246 63.0551 219.59 63.1851C218.934 63.3152 218.253 63.2 217.677 62.8615Z"
        fill="#A0616A"
      />
      <path
        d="M220.836 38.6205C220.715 38.763 220.561 38.8726 220.386 38.9391L215.084 40.9626C214.818 41.0643 214.523 41.06 214.259 40.9506C213.996 40.8413 213.785 40.6352 213.669 40.3747L208.972 29.8197C208.521 28.8406 208.445 27.7303 208.757 26.6985C208.987 25.9621 209.412 25.3015 209.987 24.7863C210.561 24.2712 211.264 23.9206 212.021 23.7715C212.604 23.6516 213.205 23.6601 213.784 23.7964C214.477 23.951 215.121 24.2761 215.657 24.7422C216.194 25.2083 216.605 25.8007 216.855 26.4659L221.031 37.5051C221.101 37.6914 221.12 37.8931 221.085 38.0893C221.051 38.2854 220.965 38.4688 220.836 38.6205Z"
        fill="#CCCCCC"
      />
      <path
        d="M193.207 113.391H189.539L188.208 99.1746L193.207 99.2415V113.391Z"
        fill="#A0616A"
      />
      <path
        d="M186.919 112.343H193.992V116.797H182.463C182.463 115.616 182.933 114.483 183.768 113.647C184.604 112.812 185.737 112.343 186.918 112.343H186.919Z"
        fill="#2F2E41"
      />
      <path
        d="M210.263 113.391H206.595L204.849 99.2415H210.263L210.263 113.391Z"
        fill="#A0616A"
      />
      <path
        d="M203.974 112.343H211.048V116.797H199.519C199.519 115.616 199.988 114.483 200.824 113.647C201.659 112.812 202.792 112.343 203.973 112.343H203.974Z"
        fill="#2F2E41"
      />
      <path
        d="M210.724 108.357L204.963 107.93C204.636 107.906 204.328 107.763 204.098 107.527C203.868 107.292 203.733 106.981 203.716 106.653L202.432 80.1029C202.419 79.8482 202.315 79.6067 202.137 79.4238C201.959 79.2408 201.721 79.129 201.467 79.1093C201.212 79.0896 200.96 79.1633 200.756 79.3166C200.552 79.47 200.411 79.6924 200.36 79.9422L195.001 106.096C194.935 106.418 194.753 106.704 194.49 106.902C194.228 107.099 193.902 107.194 193.574 107.168L188.366 106.751C188.19 106.737 188.018 106.689 187.861 106.608C187.703 106.528 187.563 106.417 187.448 106.282C187.334 106.148 187.247 105.992 187.192 105.824C187.138 105.655 187.117 105.478 187.131 105.302C187.132 105.293 187.133 105.284 187.134 105.275L191.965 56.9587C191.984 56.7699 192.043 56.5872 192.137 56.4226C192.232 56.2579 192.36 56.1152 192.514 56.0036C192.667 55.892 192.842 55.8142 193.028 55.7751C193.214 55.7361 193.406 55.7368 193.591 55.7771L211.965 59.7713C212.269 59.8381 212.541 60.0079 212.734 60.2521C212.927 60.4962 213.03 60.7997 213.025 61.111L212.17 107.039C212.163 107.391 212.019 107.727 211.768 107.974C211.517 108.221 211.179 108.36 210.827 108.361C210.793 108.361 210.758 108.359 210.724 108.357Z"
        fill="#2F2E41"
      />
      <path
        d="M205.684 16.5605C209.743 16.5605 213.033 13.2702 213.033 9.21139C213.033 5.1526 209.743 1.8623 205.684 1.8623C201.625 1.8623 198.335 5.1526 198.335 9.21139C198.335 13.2702 201.625 16.5605 205.684 16.5605Z"
        fill="#A0616A"
      />
      <path
        d="M194.281 60.6851C193.575 60.0515 193.014 59.2722 192.637 58.401C192.26 57.5298 192.077 56.5874 192.099 55.6385C192.099 45.0757 201.242 21.476 201.528 20.741C201.555 20.5637 201.796 20.487 203.063 19.632C204.33 18.777 206.156 18.6449 208.491 19.2414C208.728 19.3021 208.944 19.4268 209.116 19.6019C209.287 19.777 209.407 19.9957 209.463 20.2343L209.897 20.8619C209.935 21.0211 210.01 21.1693 210.116 21.2943C210.221 21.4194 210.355 21.5178 210.506 21.5818C212.389 22.3752 218.374 26.0337 216.335 39.8018C214.44 52.5914 213.671 60.1118 213.429 62.7534C213.4 63.0667 213.262 63.3601 213.04 63.5823C212.817 63.8046 212.524 63.9418 212.21 63.9698C211.287 64.0524 209.702 64.1625 207.816 64.1625C203.439 64.1612 197.438 63.5717 194.281 60.6851Z"
        fill="#CCCCCC"
      />
      <path
        d="M185.499 56.7716C185.325 56.4151 185.23 56.0245 185.223 55.6275C185.215 55.2304 185.295 54.8366 185.456 54.4736C185.617 54.1107 185.856 53.7875 186.155 53.5269C186.455 53.2662 186.808 53.0744 187.19 52.965L196.374 34.1965L201.112 37.7245L190.629 55.008C190.771 55.6616 190.668 56.3444 190.34 56.9271C190.011 57.5097 189.481 57.9516 188.848 58.169C188.216 58.3865 187.526 58.3643 186.908 58.1067C186.291 57.8492 185.79 57.3741 185.5 56.7716H185.499Z"
        fill="#A0616A"
      />
      <path
        d="M201.63 38.405C201.45 38.4559 201.261 38.4606 201.079 38.4187L195.548 37.1446C195.27 37.0807 195.028 36.9127 194.87 36.6752C194.713 36.4378 194.652 36.1489 194.701 35.8681L196.684 24.4865C196.855 23.4222 197.41 22.4575 198.243 21.7744C198.845 21.2914 199.566 20.9798 200.33 20.8722C201.095 20.7646 201.874 20.8652 202.585 21.1632C203.136 21.3882 203.63 21.7303 204.035 22.1659C204.525 22.6806 204.878 23.3094 205.064 23.9952C205.249 24.681 205.261 25.4022 205.097 26.0936L202.413 37.5862C202.368 37.7801 202.271 37.9584 202.133 38.1024C201.996 38.2464 201.822 38.3509 201.63 38.405H201.63Z"
        fill="#CCCCCC"
      />
      <path
        d="M203.963 15.8513C203.605 15.4864 203.341 15.0404 203.193 14.5513C203.045 14.0622 203.018 13.5447 203.113 13.0427C203.37 11.703 203.52 10.8255 203.549 10.5064C203.646 9.41505 202.543 8.53137 201.451 8.38963C201.409 8.384 201.374 8.38052 201.344 8.37811C201.371 8.4049 201.402 8.43331 201.428 8.4585C201.635 8.65329 201.893 8.89577 201.832 9.14603C201.795 9.29661 201.657 9.40352 201.411 9.47238C199.704 9.95012 198.389 9.5115 197.273 8.09115C196.959 7.68752 196.743 7.21651 196.643 6.71527C196.243 4.75394 197.308 3.08199 198.272 2.02497C199.067 1.15335 200.356 0.0957877 201.981 0.00468775C203.244 -0.0657807 204.747 0.66141 205.196 1.97191C205.408 1.65787 205.691 1.39753 206.021 1.21151C206.351 1.02548 206.72 0.918882 207.098 0.900145C207.961 0.881438 208.81 1.11761 209.54 1.57911C213.103 3.62029 214.635 8.48288 212.884 12.1976C211.783 14.5343 209.397 16.2738 206.657 16.7373C206.486 16.7665 206.312 16.7812 206.138 16.7813C205.732 16.7806 205.329 16.698 204.955 16.5384C204.581 16.3788 204.243 16.1454 203.961 15.8523L203.963 15.8513Z"
        fill="#2F2E41"
      />
      <path
        d="M97.4395 52.401C97.285 52.034 97.0262 51.7204 96.6952 51.4989C96.3642 51.2775 95.9756 51.1579 95.5774 51.1551H58.4072C57.8729 51.1554 57.3606 51.3678 56.9828 51.7456C56.605 52.1234 56.3926 52.6357 56.3923 53.17V100.194C56.3926 100.728 56.605 101.24 56.9828 101.618C57.3606 101.996 57.8729 102.208 58.4072 102.209H95.5787C96.1129 102.208 96.625 101.995 97.0027 101.618C97.3804 101.24 97.5929 100.728 97.5936 100.194V53.17C97.5945 52.9061 97.5426 52.6446 97.4409 52.401H97.4395ZM97.1448 100.194C97.1443 100.609 96.979 101.008 96.6852 101.301C96.3913 101.595 95.9929 101.761 95.5774 101.761H58.4072C57.9916 101.761 57.593 101.596 57.2991 101.302C57.0051 101.008 56.8399 100.61 56.8398 100.194V53.1703C56.8404 52.7547 57.0057 52.3564 57.2995 52.0625C57.5933 51.7687 57.9917 51.6034 58.4072 51.6028H95.5787C95.8917 51.604 96.1973 51.6984 96.4565 51.8741C96.7156 52.0497 96.9165 52.2985 97.0336 52.5888C97.0564 52.6467 97.0761 52.7057 97.0926 52.7657C97.1281 52.8976 97.1462 53.0336 97.1462 53.1703L97.1448 100.194Z"
        fill="#3F3D56"
      />
      <path
        d="M89.6822 62.2576H78.4861C78.2485 62.2576 78.0207 62.1633 77.8527 61.9953C77.6847 61.8273 77.5903 61.5995 77.5903 61.3619C77.5903 61.1244 77.6847 60.8965 77.8527 60.7285C78.0207 60.5606 78.2485 60.4662 78.4861 60.4662H89.6822C89.9198 60.4662 90.1476 60.5606 90.3156 60.7285C90.4836 60.8965 90.578 61.1244 90.578 61.3619C90.578 61.5995 90.4836 61.8273 90.3156 61.9953C90.1476 62.1633 89.9198 62.2576 89.6822 62.2576Z"
        fill="#3F3D56"
      />
      <path
        d="M89.6822 66.9597H78.4861C78.2485 66.9597 78.0207 66.8654 77.8527 66.6974C77.6847 66.5294 77.5903 66.3016 77.5903 66.064C77.5903 65.8264 77.6847 65.5986 77.8527 65.4306C78.0207 65.2626 78.2485 65.1683 78.4861 65.1683H89.6822C89.9198 65.1683 90.1476 65.2626 90.3156 65.4306C90.4836 65.5986 90.578 65.8264 90.578 66.064C90.578 66.3016 90.4836 66.5294 90.3156 66.6974C90.1476 66.8654 89.9198 66.9597 89.6822 66.9597Z"
        fill="#3F3D56"
      />
      <path
        d="M72.7416 69.6373H64.5595C64.2923 69.637 64.0361 69.5307 63.8472 69.3418C63.6583 69.1529 63.552 68.8967 63.5518 68.6296V58.974C63.552 58.7069 63.6583 58.4507 63.8472 58.2618C64.0361 58.0728 64.2923 57.9666 64.5595 57.9663H72.7416C73.0088 57.9666 73.2649 58.0728 73.4538 58.2618C73.6428 58.4507 73.749 58.7069 73.7493 58.974V68.6296C73.749 68.8967 73.6428 69.1529 73.4538 69.3418C73.2649 69.5307 73.0088 69.637 72.7416 69.6373Z"
        fill="#005CFF"
      />
      <path
        d="M89.6048 76.5885H64.3016C64.064 76.5885 63.8362 76.4941 63.6682 76.3262C63.5002 76.1582 63.4058 75.9304 63.4058 75.6928C63.4058 75.4552 63.5002 75.2274 63.6682 75.0594C63.8362 74.8914 64.064 74.7971 64.3016 74.7971H89.6048C89.7225 74.7971 89.8389 74.8202 89.9476 74.8652C90.0563 74.9103 90.155 74.9762 90.2382 75.0594C90.3214 75.1426 90.3874 75.2413 90.4324 75.35C90.4774 75.4587 90.5006 75.5752 90.5006 75.6928C90.5006 75.8104 90.4774 75.9269 90.4324 76.0356C90.3874 76.1442 90.3214 76.243 90.2382 76.3262C90.155 76.4093 90.0563 76.4753 89.9476 76.5203C89.8389 76.5653 89.7225 76.5885 89.6048 76.5885Z"
        fill="#CCCCCC"
      />
      <path
        d="M89.6048 81.2906H64.3016C64.064 81.2906 63.8362 81.1962 63.6682 81.0282C63.5002 80.8603 63.4058 80.6324 63.4058 80.3949C63.4058 80.1573 63.5002 79.9295 63.6682 79.7615C63.8362 79.5935 64.064 79.4991 64.3016 79.4991H89.6048C89.8424 79.4991 90.0702 79.5935 90.2382 79.7615C90.4062 79.9295 90.5006 80.1573 90.5006 80.3949C90.5006 80.6324 90.4062 80.8603 90.2382 81.0282C90.0702 81.1962 89.8424 81.2906 89.6048 81.2906Z"
        fill="#CCCCCC"
      />
      <path
        d="M89.6048 85.9931H64.3016C64.064 85.9931 63.8362 85.8987 63.6682 85.7308C63.5002 85.5628 63.4058 85.335 63.4058 85.0974C63.4058 84.8598 63.5002 84.632 63.6682 84.464C63.8362 84.296 64.064 84.2017 64.3016 84.2017H89.6048C89.8424 84.2017 90.0702 84.296 90.2382 84.464C90.4062 84.632 90.5006 84.8598 90.5006 85.0974C90.5006 85.335 90.4062 85.5628 90.2382 85.7308C90.0702 85.8987 89.8424 85.9931 89.6048 85.9931Z"
        fill="#CCCCCC"
      />
      <path
        d="M89.6048 90.6957H64.3016C64.064 90.6957 63.8362 90.6013 63.6682 90.4333C63.5002 90.2654 63.4058 90.0375 63.4058 89.8C63.4058 89.5624 63.5002 89.3346 63.6682 89.1666C63.8362 88.9986 64.064 88.9042 64.3016 88.9042H89.6048C89.8424 88.9042 90.0702 88.9986 90.2382 89.1666C90.4062 89.3346 90.5006 89.5624 90.5006 89.8C90.5006 90.0375 90.4062 90.2654 90.2382 90.4333C90.0702 90.6013 89.8424 90.6957 89.6048 90.6957Z"
        fill="#CCCCCC"
      />
      <path
        d="M89.6048 95.3978H64.3016C64.064 95.3978 63.8362 95.3034 63.6682 95.1354C63.5002 94.9674 63.4058 94.7396 63.4058 94.502C63.4058 94.2645 63.5002 94.0367 63.6682 93.8687C63.8362 93.7007 64.064 93.6063 64.3016 93.6063H89.6048C89.8424 93.6063 90.0702 93.7007 90.2382 93.8687C90.4062 94.0367 90.5006 94.2645 90.5006 94.502C90.5006 94.7396 90.4062 94.9674 90.2382 95.1354C90.0702 95.3034 89.8424 95.3978 89.6048 95.3978Z"
        fill="#CCCCCC"
      />
      <path
        d="M171.484 117.214H223.732C223.803 117.214 223.871 117.186 223.922 117.135C223.972 117.085 224 117.017 224 116.946C224 116.875 223.972 116.807 223.922 116.757C223.871 116.706 223.803 116.678 223.732 116.678H171.484C171.413 116.678 171.344 116.706 171.294 116.757C171.244 116.807 171.216 116.875 171.216 116.946C171.216 117.017 171.244 117.085 171.294 117.135C171.344 117.186 171.413 117.214 171.484 117.214Z"
        fill="#3F3D56"
      />
      <path
        d="M77.6217 39.8252C82.3958 39.8252 86.266 35.9551 86.266 31.1809C86.266 26.4068 82.3958 22.5366 77.6217 22.5366C72.8476 22.5366 68.9774 26.4068 68.9774 31.1809C68.9774 35.9551 72.8476 39.8252 77.6217 39.8252Z"
        fill="#005CFF"
      />
      <path
        d="M76.7649 34.4783C76.5704 34.4786 76.3811 34.4157 76.2255 34.299L76.2159 34.2918L74.1844 32.7377C74.0901 32.6657 74.011 32.5757 73.9516 32.473C73.8921 32.3704 73.8535 32.257 73.8379 32.1394C73.8223 32.0218 73.8301 31.9022 73.8607 31.7876C73.8913 31.673 73.9442 31.5656 74.0164 31.4714C74.0886 31.3773 74.1786 31.2982 74.2813 31.2389C74.3841 31.1796 74.4975 31.1411 74.6151 31.1256C74.7328 31.1101 74.8523 31.118 74.9669 31.1487C75.0814 31.1795 75.1888 31.2325 75.2829 31.3048L76.5988 32.3139L79.7082 28.2572C79.8539 28.0673 80.069 27.943 80.3062 27.9116C80.5435 27.8803 80.7835 27.9445 80.9735 28.0901L80.9737 28.0903L80.9544 28.1171L80.9743 28.0903C81.164 28.2361 81.2881 28.4513 81.3194 28.6885C81.3507 28.9258 81.2866 29.1658 81.1412 29.3558L77.4838 34.1251C77.3992 34.235 77.2904 34.324 77.1659 34.385C77.0413 34.4461 76.9044 34.4776 76.7657 34.4772L76.7649 34.4783Z"
        fill="white"
      />
      <path
        d="M20.711 27.2319C23.9462 27.2319 26.5688 24.6093 26.5688 21.3741C26.5688 18.139 23.9462 15.5164 20.711 15.5164C17.4759 15.5164 14.8533 18.139 14.8533 21.3741C14.8533 24.6093 17.4759 27.2319 20.711 27.2319Z"
        fill="#CCCCCC"
      />
      <path
        d="M20.1304 23.6086C19.9986 23.6088 19.8704 23.5662 19.7649 23.4872L19.7585 23.4824L18.3818 22.4283C18.2568 22.3284 18.1759 22.1835 18.1565 22.0246C18.1371 21.8657 18.1807 21.7055 18.2781 21.5785C18.3754 21.4514 18.5187 21.3676 18.6771 21.3449C18.8356 21.3223 18.9966 21.3626 19.1256 21.4573L20.0173 22.1411L22.1244 19.3931C22.2231 19.2644 22.3688 19.1802 22.5296 19.1589C22.6904 19.1377 22.853 19.1812 22.9817 19.2799L22.9818 19.28L22.9687 19.2977L22.9821 19.2803C23.1106 19.3791 23.1947 19.5249 23.2159 19.6856C23.2371 19.8464 23.1937 20.0089 23.0952 20.1377L20.6167 23.3701C20.5594 23.4446 20.4857 23.5049 20.4013 23.5463C20.3169 23.5877 20.2241 23.6091 20.1301 23.6089L20.1304 23.6086Z"
        fill="white"
      />
      <path
        d="M37.172 35.1092C37.0504 34.8205 36.8467 34.5737 36.5864 34.3995C36.326 34.2252 36.0202 34.1311 35.7069 34.1288H6.45821C6.0378 34.129 5.63467 34.2961 5.33739 34.5934C5.04012 34.8906 4.87302 35.2938 4.8728 35.7142V72.715C4.87302 73.1354 5.04012 73.5385 5.33739 73.8358C5.63467 74.1331 6.0378 74.3002 6.45821 74.3004H35.7069C36.1272 74.2998 36.5301 74.1326 36.8273 73.8354C37.1245 73.5382 37.2918 73.1353 37.2923 72.715V35.7142C37.293 35.5065 37.2521 35.3008 37.172 35.1092ZM36.9403 72.7152C36.9396 73.0419 36.8096 73.355 36.5785 73.5861C36.3475 73.8171 36.0344 73.9471 35.7077 73.9478H6.45821C6.13132 73.9478 5.81783 73.8179 5.58668 73.5868C5.35554 73.3556 5.22568 73.0421 5.22568 72.7152V35.7142C5.22632 35.3875 5.35638 35.0744 5.58739 34.8434C5.81839 34.6124 6.13152 34.4823 6.45821 34.4817H35.7069C35.9534 34.4827 36.194 34.5571 36.3979 34.6955C36.6018 34.834 36.7599 35.03 36.8518 35.2587C36.8698 35.3042 36.8852 35.3506 36.8982 35.3978C36.9262 35.5015 36.9405 35.6086 36.9405 35.7161L36.9403 72.7152Z"
        fill="#CCCCCC"
      />
      <path
        d="M31.0672 42.8647H22.2573C22.0704 42.8647 21.8911 42.7905 21.7589 42.6583C21.6268 42.5261 21.5525 42.3468 21.5525 42.1599C21.5525 41.973 21.6268 41.7937 21.7589 41.6615C21.8911 41.5293 22.0704 41.4551 22.2573 41.4551H31.0669C31.2539 41.4551 31.4332 41.5293 31.5653 41.6615C31.6975 41.7937 31.7718 41.973 31.7718 42.1599C31.7718 42.3468 31.6975 42.5261 31.5653 42.6583C31.4332 42.7905 31.2539 42.8647 31.0669 42.8647H31.0672Z"
        fill="#CCCCCC"
      />
      <path
        d="M31.0672 46.5648H22.2573C22.0704 46.5648 21.8911 46.4905 21.7589 46.3584C21.6268 46.2262 21.5525 46.0469 21.5525 45.86C21.5525 45.673 21.6268 45.4938 21.7589 45.3616C21.8911 45.2294 22.0704 45.1552 22.2573 45.1552H31.0669C31.2539 45.1552 31.4332 45.2294 31.5653 45.3616C31.6975 45.4938 31.7718 45.673 31.7718 45.86C31.7718 46.0469 31.6975 46.2262 31.5653 46.3584C31.4332 46.4905 31.2539 46.5648 31.0669 46.5648H31.0672Z"
        fill="#CCCCCC"
      />
      <path
        d="M17.7372 48.6716H11.2991C11.0889 48.6714 10.8874 48.5878 10.7387 48.4392C10.5901 48.2905 10.5065 48.089 10.5063 47.8788V40.2813C10.5065 40.0711 10.5901 39.8696 10.7387 39.7209C10.8874 39.5723 11.0889 39.4887 11.2991 39.4885H17.7372C17.9474 39.4887 18.1489 39.5724 18.2975 39.721C18.4461 39.8696 18.5298 40.0711 18.53 40.2813V47.8788C18.5298 48.089 18.4462 48.2905 18.2976 48.4392C18.149 48.5878 17.9474 48.6714 17.7372 48.6716Z"
        fill="#CCCCCC"
      />
      <path
        d="M31.006 54.141H11.0961C10.9092 54.141 10.7299 54.0668 10.5978 53.9346C10.4656 53.8024 10.3913 53.6231 10.3913 53.4362C10.3913 53.2493 10.4656 53.07 10.5978 52.9378C10.7299 52.8056 10.9092 52.7314 11.0961 52.7314H31.006C31.193 52.7314 31.3722 52.8056 31.5044 52.9378C31.6366 53.07 31.7109 53.2493 31.7109 53.4362C31.7109 53.6231 31.6366 53.8024 31.5044 53.9346C31.3722 54.0668 31.193 54.141 31.006 54.141Z"
        fill="#CCCCCC"
      />
      <path
        d="M31.006 57.8424H11.0961C10.9092 57.8424 10.7299 57.7682 10.5978 57.636C10.4656 57.5038 10.3913 57.3245 10.3913 57.1376C10.3913 56.9507 10.4656 56.7714 10.5978 56.6392C10.7299 56.5071 10.9092 56.4328 11.0961 56.4328H31.006C31.193 56.4328 31.3722 56.5071 31.5044 56.6392C31.6366 56.7714 31.7109 56.9507 31.7109 57.1376C31.7109 57.3245 31.6366 57.5038 31.5044 57.636C31.3722 57.7682 31.193 57.8424 31.006 57.8424Z"
        fill="#CCCCCC"
      />
      <path
        d="M31.006 61.5417H11.0961C10.9092 61.5417 10.7299 61.4674 10.5978 61.3352C10.4656 61.203 10.3913 61.0238 10.3913 60.8368C10.3913 60.6499 10.4656 60.4706 10.5978 60.3385C10.7299 60.2063 10.9092 60.132 11.0961 60.132H31.006C31.193 60.132 31.3722 60.2063 31.5044 60.3385C31.6366 60.4706 31.7109 60.6499 31.7109 60.8368C31.7109 61.0238 31.6366 61.203 31.5044 61.3352C31.3722 61.4674 31.193 61.5417 31.006 61.5417Z"
        fill="#CCCCCC"
      />
      <path
        d="M31.006 65.2422H11.0961C10.9092 65.2422 10.7299 65.168 10.5978 65.0358C10.4656 64.9036 10.3913 64.7243 10.3913 64.5374C10.3913 64.3505 10.4656 64.1712 10.5978 64.039C10.7299 63.9068 10.9092 63.8326 11.0961 63.8326H31.006C31.193 63.8326 31.3722 63.9068 31.5044 64.039C31.6366 64.1712 31.7109 64.3505 31.7109 64.5374C31.7109 64.7243 31.6366 64.9036 31.5044 65.0358C31.3722 65.168 31.193 65.2422 31.006 65.2422Z"
        fill="#CCCCCC"
      />
      <path
        d="M31.006 68.9419H11.0961C10.9092 68.9419 10.7299 68.8676 10.5978 68.7354C10.4656 68.6032 10.3913 68.424 10.3913 68.237C10.3913 68.0501 10.4656 67.8708 10.5978 67.7387C10.7299 67.6065 10.9092 67.5322 11.0961 67.5322H31.006C31.193 67.5322 31.3722 67.6065 31.5044 67.7387C31.6366 67.8708 31.7109 68.0501 31.7109 68.237C31.7109 68.424 31.6366 68.6032 31.5044 68.7354C31.3722 68.8676 31.193 68.9419 31.006 68.9419Z"
        fill="#CCCCCC"
      />
      <path
        d="M0.212133 74.4547H41.3234C41.3793 74.4547 41.433 74.4325 41.4725 74.3929C41.5121 74.3534 41.5343 74.2998 41.5343 74.2438C41.5343 74.1879 41.5121 74.1343 41.4725 74.0947C41.433 74.0552 41.3793 74.033 41.3234 74.033H0.212133C0.184441 74.0328 0.156989 74.0381 0.131345 74.0486C0.105701 74.059 0.0823673 74.0744 0.062675 74.0939C0.0429828 74.1134 0.0273202 74.1365 0.0165777 74.162C0.00583521 74.1876 0.00022172 74.2149 6.44366e-05 74.2426C-9.28471e-05 74.2703 0.00520593 74.2978 0.0156578 74.3234C0.0261097 74.3491 0.0415126 74.3724 0.0609824 74.3921C0.0804523 74.4118 0.103609 74.4275 0.129133 74.4382C0.154657 74.4489 0.182045 74.4545 0.209737 74.4547H0.212133Z"
        fill="#CCCCCC"
      />
      <path
        d="M133.246 27.2319C136.481 27.2319 139.104 24.6093 139.104 21.3741C139.104 18.139 136.481 15.5164 133.246 15.5164C130.011 15.5164 127.389 18.139 127.389 21.3741C127.389 24.6093 130.011 27.2319 133.246 27.2319Z"
        fill="#CCCCCC"
      />
      <path
        d="M132.666 23.6086C132.534 23.6088 132.406 23.5662 132.3 23.4872L132.294 23.4824L130.917 22.4283C130.792 22.3284 130.711 22.1835 130.692 22.0246C130.672 21.8657 130.716 21.7055 130.813 21.5785C130.911 21.4514 131.054 21.3676 131.212 21.3449C131.371 21.3223 131.532 21.3626 131.661 21.4573L132.553 22.1411L134.66 19.3931C134.758 19.2644 134.904 19.1802 135.065 19.1589C135.226 19.1377 135.388 19.1812 135.517 19.2799L135.517 19.28L135.504 19.2977L135.517 19.2803C135.646 19.3791 135.73 19.5249 135.751 19.6856C135.772 19.8464 135.729 20.0089 135.63 20.1377L133.152 23.3701C133.095 23.4446 133.021 23.5049 132.937 23.5463C132.852 23.5877 132.759 23.6091 132.665 23.6089L132.666 23.6086Z"
        fill="white"
      />
      <path
        d="M149.707 35.1092C149.585 34.8205 149.382 34.5737 149.121 34.3995C148.861 34.2252 148.555 34.1311 148.242 34.1288H118.993C118.573 34.129 118.17 34.2961 117.872 34.5934C117.575 34.8906 117.408 35.2938 117.408 35.7142V72.715C117.408 73.1354 117.575 73.5385 117.872 73.8358C118.17 74.1331 118.573 74.3002 118.993 74.3004H148.242C148.662 74.2998 149.065 74.1326 149.362 73.8354C149.66 73.5382 149.827 73.1353 149.827 72.715V35.7142C149.828 35.5065 149.787 35.3008 149.707 35.1092ZM149.475 72.7152C149.475 73.0419 149.345 73.355 149.114 73.5861C148.883 73.8171 148.569 73.9471 148.243 73.9478H118.993C118.831 73.9478 118.671 73.9159 118.522 73.8539C118.372 73.792 118.236 73.7012 118.122 73.5868C118.007 73.4723 117.916 73.3364 117.855 73.1869C117.793 73.0374 117.761 72.8771 117.761 72.7152V35.7142C117.761 35.3875 117.891 35.0744 118.122 34.8434C118.353 34.6124 118.667 34.4823 118.993 34.4817H148.242C148.488 34.4827 148.729 34.5571 148.933 34.6955C149.137 34.834 149.295 35.03 149.387 35.2587C149.405 35.3042 149.42 35.3506 149.433 35.3978C149.461 35.5015 149.476 35.6086 149.476 35.7161L149.475 72.7152Z"
        fill="#CCCCCC"
      />
      <path
        d="M143.603 42.8647H134.793C134.606 42.8647 134.427 42.7905 134.294 42.6583C134.162 42.5261 134.088 42.3468 134.088 42.1599C134.088 41.973 134.162 41.7937 134.294 41.6615C134.427 41.5293 134.606 41.4551 134.793 41.4551H143.602C143.789 41.4551 143.969 41.5293 144.101 41.6615C144.233 41.7937 144.307 41.973 144.307 42.1599C144.307 42.3468 144.233 42.5261 144.101 42.6583C143.969 42.7905 143.789 42.8647 143.602 42.8647H143.603Z"
        fill="#CCCCCC"
      />
      <path
        d="M143.603 46.5648H134.793C134.606 46.5648 134.427 46.4905 134.294 46.3584C134.162 46.2262 134.088 46.0469 134.088 45.86C134.088 45.673 134.162 45.4938 134.294 45.3616C134.427 45.2294 134.606 45.1552 134.793 45.1552H143.602C143.789 45.1552 143.969 45.2294 144.101 45.3616C144.233 45.4938 144.307 45.673 144.307 45.86C144.307 46.0469 144.233 46.2262 144.101 46.3584C143.969 46.4905 143.789 46.5648 143.602 46.5648H143.603Z"
        fill="#CCCCCC"
      />
      <path
        d="M130.272 48.6716H123.834C123.624 48.6714 123.423 48.5878 123.274 48.4392C123.125 48.2905 123.042 48.089 123.042 47.8788V40.2813C123.042 40.0711 123.125 39.8696 123.274 39.7209C123.423 39.5723 123.624 39.4887 123.834 39.4885H130.272C130.483 39.4887 130.684 39.5724 130.833 39.721C130.981 39.8696 131.065 40.0711 131.065 40.2813V47.8788C131.065 48.089 130.982 48.2905 130.833 48.4392C130.684 48.5878 130.483 48.6714 130.272 48.6716Z"
        fill="#CCCCCC"
      />
      <path
        d="M143.541 54.141H123.631C123.444 54.141 123.265 54.0668 123.133 53.9346C123.001 53.8024 122.927 53.6231 122.927 53.4362C122.927 53.2493 123.001 53.07 123.133 52.9378C123.265 52.8056 123.444 52.7314 123.631 52.7314H143.541C143.728 52.7314 143.908 52.8056 144.04 52.9378C144.172 53.07 144.246 53.2493 144.246 53.4362C144.246 53.6231 144.172 53.8024 144.04 53.9346C143.908 54.0668 143.728 54.141 143.541 54.141Z"
        fill="#CCCCCC"
      />
      <path
        d="M143.541 57.8424H123.631C123.444 57.8424 123.265 57.7682 123.133 57.636C123.001 57.5038 122.927 57.3245 122.927 57.1376C122.927 56.9507 123.001 56.7714 123.133 56.6392C123.265 56.5071 123.444 56.4328 123.631 56.4328H143.541C143.728 56.4328 143.908 56.5071 144.04 56.6392C144.172 56.7714 144.246 56.9507 144.246 57.1376C144.246 57.3245 144.172 57.5038 144.04 57.636C143.908 57.7682 143.728 57.8424 143.541 57.8424Z"
        fill="#CCCCCC"
      />
      <path
        d="M143.541 61.5417H123.631C123.444 61.5417 123.265 61.4674 123.133 61.3352C123.001 61.203 122.927 61.0238 122.927 60.8368C122.927 60.6499 123.001 60.4706 123.133 60.3385C123.265 60.2063 123.444 60.132 123.631 60.132H143.541C143.728 60.132 143.908 60.2063 144.04 60.3385C144.172 60.4706 144.246 60.6499 144.246 60.8368C144.246 61.0238 144.172 61.203 144.04 61.3352C143.908 61.4674 143.728 61.5417 143.541 61.5417Z"
        fill="#CCCCCC"
      />
      <path
        d="M143.541 65.2422H123.631C123.444 65.2422 123.265 65.168 123.133 65.0358C123.001 64.9036 122.927 64.7243 122.927 64.5374C122.927 64.3505 123.001 64.1712 123.133 64.039C123.265 63.9068 123.444 63.8326 123.631 63.8326H143.541C143.728 63.8326 143.908 63.9068 144.04 64.039C144.172 64.1712 144.246 64.3505 144.246 64.5374C144.246 64.7243 144.172 64.9036 144.04 65.0358C143.908 65.168 143.728 65.2422 143.541 65.2422Z"
        fill="#CCCCCC"
      />
      <path
        d="M143.541 68.9419H123.631C123.444 68.9419 123.265 68.8676 123.133 68.7354C123.001 68.6032 122.927 68.424 122.927 68.237C122.927 68.0501 123.001 67.8708 123.133 67.7387C123.265 67.6065 123.444 67.5322 123.631 67.5322H143.541C143.728 67.5322 143.908 67.6065 144.04 67.7387C144.172 67.8708 144.246 68.0501 144.246 68.237C144.246 68.424 144.172 68.6032 144.04 68.7354C143.908 68.8676 143.728 68.9419 143.541 68.9419Z"
        fill="#CCCCCC"
      />
      <path
        d="M112.747 74.4547H153.859C153.915 74.4547 153.968 74.4325 154.008 74.3929C154.047 74.3534 154.07 74.2998 154.07 74.2438C154.07 74.1879 154.047 74.1343 154.008 74.0947C153.968 74.0552 153.915 74.033 153.859 74.033H112.747C112.72 74.0328 112.692 74.0381 112.667 74.0486C112.641 74.059 112.618 74.0744 112.598 74.0939C112.578 74.1134 112.563 74.1365 112.552 74.162C112.541 74.1876 112.536 74.2149 112.535 74.2426C112.535 74.2703 112.54 74.2978 112.551 74.3234C112.561 74.3491 112.577 74.3724 112.596 74.3921C112.616 74.4118 112.639 74.4275 112.664 74.4382C112.69 74.4489 112.717 74.4545 112.745 74.4547H112.747Z"
        fill="#CCCCCC"
      />
      <path
        d="M50.6423 102.369H102.891C102.962 102.369 103.03 102.341 103.08 102.291C103.131 102.241 103.159 102.172 103.159 102.101C103.159 102.03 103.131 101.962 103.08 101.912C103.03 101.862 102.962 101.833 102.891 101.833H50.6423C50.5712 101.833 50.5031 101.862 50.4528 101.912C50.4026 101.962 50.3744 102.03 50.3744 102.101C50.3744 102.172 50.4026 102.241 50.4528 102.291C50.5031 102.341 50.5712 102.369 50.6423 102.369Z"
        fill="#3F3D56"
      />
    </svg>
  );
};
