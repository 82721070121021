import { SoldHome } from 'components/common/svg';
import { PortalRoutes } from 'routes/portal.enums';

export const HomeStartsHere = (): JSX.Element => {
  return (
    <div className="bg-white">
      <div className="mx-auto flex max-w-7xl w-full pt-16 pb-0 text-center lg:py-16 lg:text-left">
        <div className="px-4  sm:px-8 xl:pr-16">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
            <span className="block xl:inline uppercase">Home starts here</span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-lg text-center text-gray-500 sm:text-xl md:mt-5 max-w-xs">
            Fast, simple, transparent manufactured housing loans.
          </p>
          <div className="mt-10 sm:flex sm:justify-center lg:justify-center">
            <div className="rounded-md shadow">
              <a
                type="button"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
                href={PortalRoutes.ApplyNowPage}
              >
                <span>Get Started</span>
              </a>
            </div>
          </div>
        </div>
        <SoldHome />
      </div>
    </div>
  );
};
